import { generatedApi } from "./api.generated";

export const TAG_TYPES = [
  "Accessorials",
  "BillingAccounts",
  "Carriers",
  "CarrierAccessorials",
  "ConfigAccessorials",
  "DimensionalFactors",
  "ExtendedAreaLevels",
  "ExtendedAreas",
  "FuelGroups",
  "FuelSurcharges",
  "FulfillmentCenters",
  "OversizedSurcharges",
  "Parcels",
  "RateCards",
  "RateRecords",
  "ServiceLevels",
  "ServiceMethods",
  "ShipperAccessorials",
  "Shippers",
  "ZipZoneMappings",
  "ZoneGroups",
  "Zones",
];

// Note: we're just doing sledgehammer invalidation here, but can switch to granular (e.g. ID-based) later if necessary
export const parcelApi = generatedApi.enhanceEndpoints({
  addTagTypes: TAG_TYPES,
  endpoints: {
    accessorialsIndex: {
      providesTags: ["Accessorials"],
    },
    accessorialsCreate: {
      invalidatesTags: (_result, error) => (!error ? ["Accessorials"] : []),
    },
    accessorialsUpdate: {
      invalidatesTags: (_result, error) => (!error ? ["Accessorials"] : []),
    },
    accessorialsDelete: {
      invalidatesTags: (_result, error) => (!error ? ["Accessorials"] : []),
    },
    billingAccountsIndex: {
      providesTags: ["BillingAccounts"],
    },
    billingAccountCreate: {
      invalidatesTags: (_result, error) => (!error ? ["BillingAccounts"] : []),
    },
    billingAccountsUpdate: {
      invalidatesTags: (_result, error) => (!error ? ["BillingAccounts"] : []),
    },
    billingAccountsDelete: {
      invalidatesTags: (_result, error) => (!error ? ["BillingAccounts"] : []),
    },
    carriersIndex: {
      providesTags: ["Carriers"],
    },
    carriersCreate: {
      invalidatesTags: (_result, error) => (!error ? ["Carriers"] : []),
    },
    carriersUpdate: {
      invalidatesTags: (_result, error) => (!error ? ["Carriers"] : []),
    },
    carriersDelete: {
      invalidatesTags: (_result, error) => (!error ? ["Carriers"] : []),
    },
    carrierAccessorialsIndex: {
      providesTags: ["CarrierAccessorials"],
    },
    accessorialsCarrierAccessorialsCreate: {
      invalidatesTags: (_result, error) =>
        !error ? ["CarrierAccessorials"] : [],
    },
    accessorialsCarrierAccessorialsUpdate: {
      invalidatesTags: (_result, error) =>
        !error ? ["CarrierAccessorials"] : [],
    },
    accessorialsCarrierAccessorialsDelete: {
      invalidatesTags: (_result, error) =>
        !error ? ["CarrierAccessorials"] : [],
    },
    accessorialsConfigsIndex: {
      providesTags: ["ConfigAccessorials"],
    },
    accessorialsAccessorialsConfigsCreate: {
      invalidatesTags: (_result, error) =>
        !error ? ["ConfigAccessorials"] : [],
    },
    accessorialsAccessorialsConfigsUpdate: {
      invalidatesTags: (_result, error) =>
        !error ? ["ConfigAccessorials"] : [],
    },
    accessorialsAccessorialsConfigsDelete: {
      invalidatesTags: (_result, error) =>
        !error ? ["ConfigAccessorials"] : [],
    },
    dimensionalFactorsIndex: {
      providesTags: ["DimensionalFactors"],
    },
    dimensionalFactorsCreate: {
      invalidatesTags: (_result, error) =>
        !error ? ["DimensionalFactors"] : [],
    },
    dimensionalFactorsUpdate: {
      invalidatesTags: (_result, error) =>
        !error ? ["DimensionalFactors"] : [],
    },
    dimensionalFactorsDelete: {
      invalidatesTags: (_result, error) =>
        !error ? ["DimensionalFactors"] : [],
    },
    extendedAreaLevelsIndex: {
      providesTags: ["ExtendedAreaLevels"],
    },
    extendedAreaLevelsCreate: {
      invalidatesTags: (_result, error) =>
        !error ? ["ExtendedAreaLevels"] : [],
    },
    extendedAreaLevelsUpdate: {
      invalidatesTags: (_result, error) =>
        !error ? ["ExtendedAreaLevels"] : [],
    },
    extendedAreaLevelsDelete: {
      invalidatesTags: (_result, error) =>
        !error ? ["ExtendedAreaLevels"] : [],
    },
    extendedAreasIndex: {
      providesTags: ["ExtendedAreas"],
    },
    extendedAreaCreate: {
      invalidatesTags: (_result, error) => (!error ? ["ExtendedAreas"] : []),
    },
    extendedAreasUpdate: {
      invalidatesTags: (_result, error) => (!error ? ["ExtendedAreas"] : []),
    },
    extendedAreasDelete: {
      invalidatesTags: (_result, error) => (!error ? ["ExtendedAreas"] : []),
    },
    fuelGroupsIndex: {
      providesTags: ["FuelGroups"],
    },
    fuelGroupsCreate: {
      invalidatesTags: (_result, error) => (!error ? ["FuelGroups"] : []),
    },
    fuelGroupsUpdate: {
      invalidatesTags: (_result, error) => (!error ? ["FuelGroups"] : []),
    },
    fuelGroupsDelete: {
      invalidatesTags: (_result, error) => (!error ? ["FuelGroups"] : []),
    },
    fuelGroupsFuelSurchargesIndex: {
      providesTags: ["FuelSurcharges"],
    },
    fuelGroupsFuelSurchargesCreate: {
      invalidatesTags: (_result, error) => (!error ? ["FuelSurcharges"] : []),
    },
    fuelGroupsFuelSurchargesUpdate: {
      invalidatesTags: (_result, error) => (!error ? ["FuelSurcharges"] : []),
    },
    fuelGroupsFuelSurchargesDelete: {
      invalidatesTags: (_result, error) => (!error ? ["FuelSurcharges"] : []),
    },
    fulfillmentCentersIndex: {
      providesTags: ["FulfillmentCenters"],
    },
    fulfillmentCentersCreate: {
      invalidatesTags: (_result, error) =>
        !error ? ["FulfillmentCenters"] : [],
    },
    fulfillmentCentersUpdate: {
      invalidatesTags: (_result, error) =>
        !error ? ["FulfillmentCenters"] : [],
    },
    fulfillmentCentersDelete: {
      invalidatesTags: (_result, error) =>
        !error ? ["FulfillmentCenters"] : [],
    },
    oversizedSurchargesIndex: {
      providesTags: ["OversizedSurcharges"],
    },
    oversizedSurchargesCreate: {
      invalidatesTags: (_result, error) =>
        !error ? ["OversizedSurcharges"] : [],
    },
    oversizedSurchargesUpdate: {
      invalidatesTags: (_result, error) =>
        !error ? ["OversizedSurcharges"] : [],
    },
    oversizedSurchargesDelete: {
      invalidatesTags: (_result, error) =>
        !error ? ["OversizedSurcharges"] : [],
    },
    parcelsIndex: {
      providesTags: ["Parcels"],
    },
    parcelCreate: {
      invalidatesTags: (_result, error) => (!error ? ["Parcels"] : []),
    },
    parcelVoid: {
      invalidatesTags: (_result, error) => (!error ? ["Parcels"] : []),
    },
    shippersRateCardsIndex: {
      providesTags: ["RateCards"],
    },
    rateCardsIndex: {
      providesTags: ["RateCards"],
    },
    shippersRateCardsCreate: {
      invalidatesTags: (_result, error) => (!error ? ["RateCards"] : []),
    },
    shippersRateCardsUpdate: {
      invalidatesTags: (_result, error) => (!error ? ["RateCards"] : []),
    },
    shippersRateCardsDelete: {
      invalidatesTags: (_result, error) => (!error ? ["RateCards"] : []),
    },
    shippersRateCardsRateRecordsIndex: {
      providesTags: ["RateRecords"],
    },
    shippersRateCardsRateRecordsCreate: {
      invalidatesTags: (_result, error) => (!error ? ["RateRecords"] : []),
    },
    shippersRateCardsRateRecordsUpdate: {
      invalidatesTags: (_result, error) => (!error ? ["RateRecords"] : []),
    },
    shippersRateCardsRateRecordsDelete: {
      invalidatesTags: (_result, error) => (!error ? ["RateRecords"] : []),
    },
    serviceLevelsIndex: {
      providesTags: ["ServiceLevels"],
    },
    serviceLevelsCreate: {
      invalidatesTags: (_result, error) => (!error ? ["ServiceLevels"] : []),
    },
    serviceLevelsUpdate: {
      invalidatesTags: (_result, error) => (!error ? ["ServiceLevels"] : []),
    },
    serviceLevelsDelete: {
      invalidatesTags: (_result, error) => (!error ? ["ServiceLevels"] : []),
    },
    carriersServiceMethodsIndex: {
      providesTags: ["ServiceMethods"],
    },
    carrierServiceMethodsCreate: {
      invalidatesTags: (_result, error) => (!error ? ["ServiceMethods"] : []),
    },
    carriersServiceMethodsUpdate: {
      invalidatesTags: (_result, error) => (!error ? ["ServiceMethods"] : []),
    },
    carriersServiceMethodsDelete: {
      invalidatesTags: (_result, error) => (!error ? ["ServiceMethods"] : []),
    },
    shippersAccessorialsIndex: {
      providesTags: ["ShipperAccessorials"],
    },
    accessorialsShippersAccessorialsCreate: {
      invalidatesTags: (_result, error) =>
        !error ? ["ShipperAccessorials"] : [],
    },
    accessorialsShipperAccessorialsUpdate: {
      invalidatesTags: (_result, error) =>
        !error ? ["ShipperAccessorials"] : [],
    },
    accessorialsShipperAccessorialsDelete: {
      invalidatesTags: (_result, error) =>
        !error ? ["ShipperAccessorials"] : [],
    },
    shippersIndex: {
      providesTags: ["Shippers"],
    },
    shippersCreate: {
      invalidatesTags: (_result, error) => (!error ? ["Shippers"] : []),
    },
    shippersUpdate: {
      invalidatesTags: (_result, error) => (!error ? ["Shippers"] : []),
    },
    zoneGroupsIndex: {
      providesTags: ["ZoneGroups"],
    },
    zoneGroupsCreate: {
      invalidatesTags: (_result, error) => (!error ? ["ZoneGroups"] : []),
    },
    zoneGroupsUpdate: {
      invalidatesTags: (_result, error) => (!error ? ["ZoneGroups"] : []),
    },
    zoneGroupsDelete: {
      invalidatesTags: (_result, error) => (!error ? ["ZoneGroups"] : []),
    },
    zoneGroupsZonesIndex: {
      providesTags: ["Zones"],
    },
    zoneGroupsZonesCreate: {
      invalidatesTags: (_result, error) => (!error ? ["Zones"] : []),
    },
    zoneGroupsZonesUpdate: {
      invalidatesTags: (_result, error) => (!error ? ["Zones"] : []),
    },
    zoneGroupsZonesDelete: {
      invalidatesTags: (_result, error) => (!error ? ["Zones"] : []),
    },
    zipZoneMappingsIndex: {
      providesTags: ["ZipZoneMappings"],
    },
    zipZoneMappingsUpsert: {
      invalidatesTags: (_result, error) => (!error ? ["ZipZoneMappings"] : []),
    },
  },
});

export type ParcelApi = typeof parcelApi;
