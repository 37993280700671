import { parcelApi as api } from "./base.api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    zoneGroupsZonesDelete: build.mutation<
      ZoneGroupsZonesDeleteApiResponse,
      ZoneGroupsZonesDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.zoneGroupId}/zones/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    zoneGroupsZonesShow: build.query<
      ZoneGroupsZonesShowApiResponse,
      ZoneGroupsZonesShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.zoneGroupId}/zones/${queryArg.id}`,
      }),
    }),
    zoneGroupsZonesUpdate: build.mutation<
      ZoneGroupsZonesUpdateApiResponse,
      ZoneGroupsZonesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.zoneGroupId}/zones/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ZoneGroupsByZoneGroupIdZonesAndId: build.mutation<
      PutV1ZoneGroupsByZoneGroupIdZonesAndIdApiResponse,
      PutV1ZoneGroupsByZoneGroupIdZonesAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.zoneGroupId}/zones/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    extendedAreaSurchargesDelete: build.mutation<
      ExtendedAreaSurchargesDeleteApiResponse,
      ExtendedAreaSurchargesDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_surcharges/${queryArg}`,
        method: "DELETE",
      }),
    }),
    extendedAreaSurchargesShow: build.query<
      ExtendedAreaSurchargesShowApiResponse,
      ExtendedAreaSurchargesShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_surcharges/${queryArg}`,
      }),
    }),
    extendedAreaSurchargesUpdate: build.mutation<
      ExtendedAreaSurchargesUpdateApiResponse,
      ExtendedAreaSurchargesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_surcharges/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ExtendedAreaSurchargesById: build.mutation<
      PutV1ExtendedAreaSurchargesByIdApiResponse,
      PutV1ExtendedAreaSurchargesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_surcharges/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    carrierAccountsIndex: build.query<
      CarrierAccountsIndexApiResponse,
      CarrierAccountsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    carrierAccountCreate: build.mutation<
      CarrierAccountCreateApiResponse,
      CarrierAccountCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts`,
        method: "POST",
        body: queryArg,
      }),
    }),
    zipZoneMappingsExport: build.query<
      ZipZoneMappingsExportApiResponse,
      ZipZoneMappingsExportApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zip_zone_mappings/export`,
        params: { sort: queryArg.sort, filter: queryArg.filter },
      }),
    }),
    shippersAccessorialsIndex: build.query<
      ShippersAccessorialsIndexApiResponse,
      ShippersAccessorialsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shipper_accessorials`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    shipperCarrierFulfillmentsDelete: build.mutation<
      ShipperCarrierFulfillmentsDeleteApiResponse,
      ShipperCarrierFulfillmentsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/carrier_accounts/${queryArg.carrierAccountId}/fulfillment_centers/${queryArg.fulfillmentCenterId}`,
        method: "DELETE",
      }),
    }),
    shipperCarrierFulfillmentsCreate: build.mutation<
      ShipperCarrierFulfillmentsCreateApiResponse,
      ShipperCarrierFulfillmentsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/carrier_accounts/${queryArg.carrierAccountId}/fulfillment_centers/${queryArg.fulfillmentCenterId}`,
        method: "POST",
      }),
    }),
    carriersIndex: build.query<CarriersIndexApiResponse, CarriersIndexApiArg>({
      query: (queryArg) => ({
        url: `/v1/carriers`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    carriersCreate: build.mutation<
      CarriersCreateApiResponse,
      CarriersCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers`,
        method: "POST",
        body: queryArg,
      }),
    }),
    vendorsServiceMethodMappingsDelete: build.mutation<
      VendorsServiceMethodMappingsDeleteApiResponse,
      VendorsServiceMethodMappingsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/service_method_mappings/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    vendorsServiceMethodMappingsShow: build.query<
      VendorsServiceMethodMappingsShowApiResponse,
      VendorsServiceMethodMappingsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/service_method_mappings/${queryArg.id}`,
      }),
    }),
    vendorsServiceMethodMappingsUpdate: build.mutation<
      VendorsServiceMethodMappingsUpdateApiResponse,
      VendorsServiceMethodMappingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/service_method_mappings/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1VendorsByVendorIdServiceMethodMappingsAndId: build.mutation<
      PutV1VendorsByVendorIdServiceMethodMappingsAndIdApiResponse,
      PutV1VendorsByVendorIdServiceMethodMappingsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/service_method_mappings/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    shippersIndex: build.query<ShippersIndexApiResponse, ShippersIndexApiArg>({
      query: (queryArg) => ({
        url: `/v1/shippers`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    shippersCreate: build.mutation<
      ShippersCreateApiResponse,
      ShippersCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers`,
        method: "POST",
        body: queryArg,
      }),
    }),
    serviceMethodsIndex: build.query<
      ServiceMethodsIndexApiResponse,
      ServiceMethodsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/service_methods`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    accessorialsDelete: build.mutation<
      AccessorialsDeleteApiResponse,
      AccessorialsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg}`,
        method: "DELETE",
      }),
    }),
    accessorialsShow: build.query<
      AccessorialsShowApiResponse,
      AccessorialsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/accessorials/${queryArg}` }),
    }),
    accessorialsUpdate: build.mutation<
      AccessorialsUpdateApiResponse,
      AccessorialsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1AccessorialsById: build.mutation<
      PutV1AccessorialsByIdApiResponse,
      PutV1AccessorialsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    billingAccountsIndex: build.query<
      BillingAccountsIndexApiResponse,
      BillingAccountsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    billingAccountCreate: build.mutation<
      BillingAccountCreateApiResponse,
      BillingAccountCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    fulfillmentCentersIndex: build.query<
      FulfillmentCentersIndexApiResponse,
      FulfillmentCentersIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
          search: queryArg.search,
        },
      }),
    }),
    fulfillmentCentersCreate: build.mutation<
      FulfillmentCentersCreateApiResponse,
      FulfillmentCentersCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers`,
        method: "POST",
        body: queryArg,
      }),
    }),
    vendorsCarrierMappingsIndex: build.query<
      VendorsCarrierMappingsIndexApiResponse,
      VendorsCarrierMappingsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/carrier_mappings`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    vendorsCarrierMappingsCreate: build.mutation<
      VendorsCarrierMappingsCreateApiResponse,
      VendorsCarrierMappingsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/carrier_mappings`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    shippersRateCardsIndex: build.query<
      ShippersRateCardsIndexApiResponse,
      ShippersRateCardsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/rate_cards`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    shippersRateCardsCreate: build.mutation<
      ShippersRateCardsCreateApiResponse,
      ShippersRateCardsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/rate_cards`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    registrationsDelete: build.mutation<
      RegistrationsDeleteApiResponse,
      RegistrationsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations/${queryArg}`,
        method: "DELETE",
      }),
    }),
    registrationsShow: build.query<
      RegistrationsShowApiResponse,
      RegistrationsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/registrations/${queryArg}` }),
    }),
    registrationsUpdate: build.mutation<
      RegistrationsUpdateApiResponse,
      RegistrationsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateRegistration,
      }),
    }),
    putV1RegistrationsById: build.mutation<
      PutV1RegistrationsByIdApiResponse,
      PutV1RegistrationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateRegistration,
      }),
    }),
    fulfillmentCentersDelete: build.mutation<
      FulfillmentCentersDeleteApiResponse,
      FulfillmentCentersDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers/${queryArg}`,
        method: "DELETE",
      }),
    }),
    fulfillmentCentersShow: build.query<
      FulfillmentCentersShowApiResponse,
      FulfillmentCentersShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/fulfillment_centers/${queryArg}` }),
    }),
    fulfillmentCentersUpdate: build.mutation<
      FulfillmentCentersUpdateApiResponse,
      FulfillmentCentersUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1FulfillmentCentersById: build.mutation<
      PutV1FulfillmentCentersByIdApiResponse,
      PutV1FulfillmentCentersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    accessorialsShipperAccessorialsIndex: build.query<
      AccessorialsShipperAccessorialsIndexApiResponse,
      AccessorialsShipperAccessorialsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/shipper_accessorials`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    accessorialsShippersAccessorialsCreate: build.mutation<
      AccessorialsShippersAccessorialsCreateApiResponse,
      AccessorialsShippersAccessorialsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/shipper_accessorials`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    billingAccountsDelete: build.mutation<
      BillingAccountsDeleteApiResponse,
      BillingAccountsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    billingAccountsShow: build.query<
      BillingAccountsShowApiResponse,
      BillingAccountsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts/${queryArg.id}`,
      }),
    }),
    billingAccountsUpdate: build.mutation<
      BillingAccountsUpdateApiResponse,
      BillingAccountsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ShippersByShipperIdBillingAccountsAndId: build.mutation<
      PutV1ShippersByShipperIdBillingAccountsAndIdApiResponse,
      PutV1ShippersByShipperIdBillingAccountsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    trackingEventsDelete: build.mutation<
      TrackingEventsDeleteApiResponse,
      TrackingEventsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events/${queryArg}`,
        method: "DELETE",
      }),
    }),
    trackingEventsShow: build.query<
      TrackingEventsShowApiResponse,
      TrackingEventsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/tracking_events/${queryArg}` }),
    }),
    trackingEventsUpdate: build.mutation<
      TrackingEventsUpdateApiResponse,
      TrackingEventsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events/${queryArg.trackingEventId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1TrackingEventsByTrackingEventId: build.mutation<
      PutV1TrackingEventsByTrackingEventIdApiResponse,
      PutV1TrackingEventsByTrackingEventIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events/${queryArg.trackingEventId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    vendorsDelete: build.mutation<
      VendorsDeleteApiResponse,
      VendorsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg}`,
        method: "DELETE",
      }),
    }),
    vendorsShow: build.query<VendorsShowApiResponse, VendorsShowApiArg>({
      query: (queryArg) => ({ url: `/v1/vendors/${queryArg}` }),
    }),
    vendorsUpdate: build.mutation<
      VendorsUpdateApiResponse,
      VendorsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1VendorsById: build.mutation<
      PutV1VendorsByIdApiResponse,
      PutV1VendorsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    returnLabelsCreate: build.mutation<
      ReturnLabelsCreateApiResponse,
      ReturnLabelsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/return_labels`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    oversizedSurchargesDelete: build.mutation<
      OversizedSurchargesDeleteApiResponse,
      OversizedSurchargesDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/oversized_surcharges/${queryArg}`,
        method: "DELETE",
      }),
    }),
    oversizedSurchargesShow: build.query<
      OversizedSurchargesShowApiResponse,
      OversizedSurchargesShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/oversized_surcharges/${queryArg}` }),
    }),
    oversizedSurchargesUpdate: build.mutation<
      OversizedSurchargesUpdateApiResponse,
      OversizedSurchargesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/oversized_surcharges/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1OversizedSurchargesById: build.mutation<
      PutV1OversizedSurchargesByIdApiResponse,
      PutV1OversizedSurchargesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/oversized_surcharges/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    dimensionalFactorsDelete: build.mutation<
      DimensionalFactorsDeleteApiResponse,
      DimensionalFactorsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/dimensional_factors/${queryArg}`,
        method: "DELETE",
      }),
    }),
    dimensionalFactorsShow: build.query<
      DimensionalFactorsShowApiResponse,
      DimensionalFactorsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/dimensional_factors/${queryArg}` }),
    }),
    dimensionalFactorsUpdate: build.mutation<
      DimensionalFactorsUpdateApiResponse,
      DimensionalFactorsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/dimensional_factors/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1DimensionalFactorsById: build.mutation<
      PutV1DimensionalFactorsByIdApiResponse,
      PutV1DimensionalFactorsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/dimensional_factors/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    parcelRateEstimate: build.mutation<
      ParcelRateEstimateApiResponse,
      ParcelRateEstimateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels/rate_estimate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    parcelsIndex: build.query<ParcelsIndexApiResponse, ParcelsIndexApiArg>({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    parcelCreate: build.mutation<ParcelCreateApiResponse, ParcelCreateApiArg>({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    zipZoneMappingsIndex: build.query<
      ZipZoneMappingsIndexApiResponse,
      ZipZoneMappingsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zip_zone_mappings`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    zipZoneMappingsUpsert: build.mutation<
      ZipZoneMappingsUpsertApiResponse,
      ZipZoneMappingsUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zip_zone_mappings`,
        method: "PUT",
        body: queryArg,
      }),
    }),
    accessorialsIndex: build.query<
      AccessorialsIndexApiResponse,
      AccessorialsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    accessorialsCreate: build.mutation<
      AccessorialsCreateApiResponse,
      AccessorialsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials`,
        method: "POST",
        body: queryArg,
      }),
    }),
    extendedAreasDelete: build.mutation<
      ExtendedAreasDeleteApiResponse,
      ExtendedAreasDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_areas/${queryArg}`,
        method: "DELETE",
      }),
    }),
    extendedAreasShow: build.query<
      ExtendedAreasShowApiResponse,
      ExtendedAreasShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/extended_areas/${queryArg}` }),
    }),
    extendedAreasUpdate: build.mutation<
      ExtendedAreasUpdateApiResponse,
      ExtendedAreasUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_areas/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ExtendedAreasById: build.mutation<
      PutV1ExtendedAreasByIdApiResponse,
      PutV1ExtendedAreasByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_areas/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    vendorsServiceMethodMappingsIndex: build.query<
      VendorsServiceMethodMappingsIndexApiResponse,
      VendorsServiceMethodMappingsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/service_method_mappings`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    vendorsServiceMethodMappingsCreate: build.mutation<
      VendorsServiceMethodMappingsCreateApiResponse,
      VendorsServiceMethodMappingsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/service_method_mappings`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    carrierAccountsDelete: build.mutation<
      CarrierAccountsDeleteApiResponse,
      CarrierAccountsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts/${queryArg}`,
        method: "DELETE",
      }),
    }),
    carrierAccountsShow: build.query<
      CarrierAccountsShowApiResponse,
      CarrierAccountsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/carrier_accounts/${queryArg}` }),
    }),
    carrierAccountsUpdate: build.mutation<
      CarrierAccountsUpdateApiResponse,
      CarrierAccountsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1CarrierAccountsById: build.mutation<
      PutV1CarrierAccountsByIdApiResponse,
      PutV1CarrierAccountsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    accessorialsCarrierAccessorialsDelete: build.mutation<
      AccessorialsCarrierAccessorialsDeleteApiResponse,
      AccessorialsCarrierAccessorialsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/carrier_accessorials/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    accessorialsCarrierAccessorialsShow: build.query<
      AccessorialsCarrierAccessorialsShowApiResponse,
      AccessorialsCarrierAccessorialsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/carrier_accessorials/${queryArg.id}`,
      }),
    }),
    accessorialsCarrierAccessorialsUpdate: build.mutation<
      AccessorialsCarrierAccessorialsUpdateApiResponse,
      AccessorialsCarrierAccessorialsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/carrier_accessorials/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1AccessorialsByAccessorialIdCarrierAccessorialsAndId: build.mutation<
      PutV1AccessorialsByAccessorialIdCarrierAccessorialsAndIdApiResponse,
      PutV1AccessorialsByAccessorialIdCarrierAccessorialsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/carrier_accessorials/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    parcelsRerate: build.mutation<
      ParcelsRerateApiResponse,
      ParcelsRerateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/parcels/${queryArg.parcelId}/rerate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    extendedAreasIndex: build.query<
      ExtendedAreasIndexApiResponse,
      ExtendedAreasIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_areas`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    extendedAreaCreate: build.mutation<
      ExtendedAreaCreateApiResponse,
      ExtendedAreaCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_areas`,
        method: "POST",
        body: queryArg,
      }),
    }),
    vendorsCarrierMappingsDelete: build.mutation<
      VendorsCarrierMappingsDeleteApiResponse,
      VendorsCarrierMappingsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/carrier_mappings/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    vendorsCarrierMappingsShow: build.query<
      VendorsCarrierMappingsShowApiResponse,
      VendorsCarrierMappingsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/carrier_mappings/${queryArg.id}`,
      }),
    }),
    vendorsCarrierMappingsUpdate: build.mutation<
      VendorsCarrierMappingsUpdateApiResponse,
      VendorsCarrierMappingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/carrier_mappings/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1VendorsByVendorIdCarrierMappingsAndId: build.mutation<
      PutV1VendorsByVendorIdCarrierMappingsAndIdApiResponse,
      PutV1VendorsByVendorIdCarrierMappingsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/carrier_mappings/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    accessorialsCarrierAccessorialsIndex: build.query<
      AccessorialsCarrierAccessorialsIndexApiResponse,
      AccessorialsCarrierAccessorialsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/carrier_accessorials`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    accessorialsCarrierAccessorialsCreate: build.mutation<
      AccessorialsCarrierAccessorialsCreateApiResponse,
      AccessorialsCarrierAccessorialsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/carrier_accessorials`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    shippersRateCardsDelete: build.mutation<
      ShippersRateCardsDeleteApiResponse,
      ShippersRateCardsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/rate_cards/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    shippersRateCardsShow: build.query<
      ShippersRateCardsShowApiResponse,
      ShippersRateCardsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/rate_cards/${queryArg.id}`,
      }),
    }),
    shippersRateCardsUpdate: build.mutation<
      ShippersRateCardsUpdateApiResponse,
      ShippersRateCardsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/rate_cards/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ShippersByShipperIdRateCardsAndId: build.mutation<
      PutV1ShippersByShipperIdRateCardsAndIdApiResponse,
      PutV1ShippersByShipperIdRateCardsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/rate_cards/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    zoneGroupsIndex: build.query<
      ZoneGroupsIndexApiResponse,
      ZoneGroupsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    zoneGroupsCreate: build.mutation<
      ZoneGroupsCreateApiResponse,
      ZoneGroupsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups`,
        method: "POST",
        body: queryArg,
      }),
    }),
    parcelVoid: build.mutation<ParcelVoidApiResponse, ParcelVoidApiArg>({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    parcelShow: build.query<ParcelShowApiResponse, ParcelShowApiArg>({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels/${queryArg.id}`,
      }),
    }),
    carriersServiceMethodsDelete: build.mutation<
      CarriersServiceMethodsDeleteApiResponse,
      CarriersServiceMethodsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.carrierId}/service_methods/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    carriersServiceMethodsShow: build.query<
      CarriersServiceMethodsShowApiResponse,
      CarriersServiceMethodsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.carrierId}/service_methods/${queryArg.id}`,
      }),
    }),
    carriersServiceMethodsUpdate: build.mutation<
      CarriersServiceMethodsUpdateApiResponse,
      CarriersServiceMethodsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.carrierId}/service_methods/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1CarriersByCarrierIdServiceMethodsAndId: build.mutation<
      PutV1CarriersByCarrierIdServiceMethodsAndIdApiResponse,
      PutV1CarriersByCarrierIdServiceMethodsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.carrierId}/service_methods/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    carrierAccessorialsIndex: build.query<
      CarrierAccessorialsIndexApiResponse,
      CarrierAccessorialsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accessorials`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    fuelGroupsFuelSurchargesIndex: build.query<
      FuelGroupsFuelSurchargesIndexApiResponse,
      FuelGroupsFuelSurchargesIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.fuelGroupId}/fuel_surcharges`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    fuelGroupsFuelSurchargesCreate: build.mutation<
      FuelGroupsFuelSurchargesCreateApiResponse,
      FuelGroupsFuelSurchargesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.fuelGroupId}/fuel_surcharges`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    registrationsIndex: build.query<
      RegistrationsIndexApiResponse,
      RegistrationsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    registrationsCreate: build.mutation<
      RegistrationsCreateApiResponse,
      RegistrationsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations`,
        method: "POST",
        body: queryArg,
      }),
    }),
    fuelGroupsFuelSurchargesDelete: build.mutation<
      FuelGroupsFuelSurchargesDeleteApiResponse,
      FuelGroupsFuelSurchargesDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.fuelGroupId}/fuel_surcharges/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    fuelGroupsFuelSurchargesShow: build.query<
      FuelGroupsFuelSurchargesShowApiResponse,
      FuelGroupsFuelSurchargesShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.fuelGroupId}/fuel_surcharges/${queryArg.id}`,
      }),
    }),
    fuelGroupsFuelSurchargesUpdate: build.mutation<
      FuelGroupsFuelSurchargesUpdateApiResponse,
      FuelGroupsFuelSurchargesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.fuelGroupId}/fuel_surcharges/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1FuelGroupsByFuelGroupIdFuelSurchargesAndId: build.mutation<
      PutV1FuelGroupsByFuelGroupIdFuelSurchargesAndIdApiResponse,
      PutV1FuelGroupsByFuelGroupIdFuelSurchargesAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.fuelGroupId}/fuel_surcharges/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    zoneGroupsDelete: build.mutation<
      ZoneGroupsDeleteApiResponse,
      ZoneGroupsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg}`,
        method: "DELETE",
      }),
    }),
    zoneGroupsShow: build.query<
      ZoneGroupsShowApiResponse,
      ZoneGroupsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/zone_groups/${queryArg}` }),
    }),
    zoneGroupsUpdate: build.mutation<
      ZoneGroupsUpdateApiResponse,
      ZoneGroupsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ZoneGroupsById: build.mutation<
      PutV1ZoneGroupsByIdApiResponse,
      PutV1ZoneGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    shippersRateCardsRateRecordsIndex: build.query<
      ShippersRateCardsRateRecordsIndexApiResponse,
      ShippersRateCardsRateRecordsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/rate_cards/${queryArg.rateCardId}/rate_records`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    shippersRateCardsRateRecordsCreate: build.mutation<
      ShippersRateCardsRateRecordsCreateApiResponse,
      ShippersRateCardsRateRecordsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/rate_cards/${queryArg.rateCardId}/rate_records`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    accessorialsShipperAccessorialsDelete: build.mutation<
      AccessorialsShipperAccessorialsDeleteApiResponse,
      AccessorialsShipperAccessorialsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/shipper_accessorials/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    accessorialsShipperAccessorialsShow: build.query<
      AccessorialsShipperAccessorialsShowApiResponse,
      AccessorialsShipperAccessorialsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/shipper_accessorials/${queryArg.id}`,
      }),
    }),
    accessorialsShipperAccessorialsUpdate: build.mutation<
      AccessorialsShipperAccessorialsUpdateApiResponse,
      AccessorialsShipperAccessorialsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/shipper_accessorials/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1AccessorialsByAccessorialIdShipperAccessorialsAndId: build.mutation<
      PutV1AccessorialsByAccessorialIdShipperAccessorialsAndIdApiResponse,
      PutV1AccessorialsByAccessorialIdShipperAccessorialsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/shipper_accessorials/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    accessorialsConfigsIndex: build.query<
      AccessorialsConfigsIndexApiResponse,
      AccessorialsConfigsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials_configs`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    carriersServiceMethodsIndex: build.query<
      CarriersServiceMethodsIndexApiResponse,
      CarriersServiceMethodsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.carrierId}/service_methods`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    carrierServiceMethodsCreate: build.mutation<
      CarrierServiceMethodsCreateApiResponse,
      CarrierServiceMethodsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.carrierId}/service_methods`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    parcelBatchShow: build.query<
      ParcelBatchShowApiResponse,
      ParcelBatchShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels/batches/${queryArg.batchId}`,
      }),
    }),
    easyPostWebhookEvent: build.mutation<
      EasyPostWebhookEventApiResponse,
      EasyPostWebhookEventApiArg
    >({
      query: (queryArg) => ({
        url: `/webhooks/easy_post/tracking_events`,
        method: "POST",
        body: queryArg,
      }),
    }),
    shippersRateCardsRateRecordsDelete: build.mutation<
      ShippersRateCardsRateRecordsDeleteApiResponse,
      ShippersRateCardsRateRecordsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/rate_cards/${queryArg.rateCardId}/rate_records/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    shippersRateCardsRateRecordsShow: build.query<
      ShippersRateCardsRateRecordsShowApiResponse,
      ShippersRateCardsRateRecordsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/rate_cards/${queryArg.rateCardId}/rate_records/${queryArg.id}`,
      }),
    }),
    shippersRateCardsRateRecordsUpdate: build.mutation<
      ShippersRateCardsRateRecordsUpdateApiResponse,
      ShippersRateCardsRateRecordsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/rate_cards/${queryArg.rateCardId}/rate_records/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ShippersByShipperIdRateCardsAndRateCardIdRateRecordsId: build.mutation<
      PutV1ShippersByShipperIdRateCardsAndRateCardIdRateRecordsIdApiResponse,
      PutV1ShippersByShipperIdRateCardsAndRateCardIdRateRecordsIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/rate_cards/${queryArg.rateCardId}/rate_records/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    zoneGroupsZonesIndex: build.query<
      ZoneGroupsZonesIndexApiResponse,
      ZoneGroupsZonesIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.zoneGroupId}/zones`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    zoneGroupsZonesCreate: build.mutation<
      ZoneGroupsZonesCreateApiResponse,
      ZoneGroupsZonesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.zoneGroupId}/zones`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    accessorialsAccessorialsConfigsIndex: build.query<
      AccessorialsAccessorialsConfigsIndexApiResponse,
      AccessorialsAccessorialsConfigsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/configs`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    accessorialsAccessorialsConfigsCreate: build.mutation<
      AccessorialsAccessorialsConfigsCreateApiResponse,
      AccessorialsAccessorialsConfigsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/configs`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    fuelGroupsDelete: build.mutation<
      FuelGroupsDeleteApiResponse,
      FuelGroupsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg}`,
        method: "DELETE",
      }),
    }),
    fuelGroupsShow: build.query<
      FuelGroupsShowApiResponse,
      FuelGroupsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/fuel_groups/${queryArg}` }),
    }),
    fuelGroupsUpdate: build.mutation<
      FuelGroupsUpdateApiResponse,
      FuelGroupsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1FuelGroupsById: build.mutation<
      PutV1FuelGroupsByIdApiResponse,
      PutV1FuelGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    vendorsIndex: build.query<VendorsIndexApiResponse, VendorsIndexApiArg>({
      query: (queryArg) => ({
        url: `/v1/vendors`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    vendorsCreate: build.mutation<
      VendorsCreateApiResponse,
      VendorsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors`,
        method: "POST",
        body: queryArg,
      }),
    }),
    extendedAreaSurchargesIndex: build.query<
      ExtendedAreaSurchargesIndexApiResponse,
      ExtendedAreaSurchargesIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_surcharges`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    extendedAreaSurchargesCreate: build.mutation<
      ExtendedAreaSurchargesCreateApiResponse,
      ExtendedAreaSurchargesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_surcharges`,
        method: "POST",
        body: queryArg,
      }),
    }),
    shipiumWebhookEvent: build.mutation<
      ShipiumWebhookEventApiResponse,
      ShipiumWebhookEventApiArg
    >({
      query: (queryArg) => ({
        url: `/webhooks/shipium/tracking_events`,
        method: "POST",
        body: queryArg,
      }),
    }),
    trackingEventsIndex: build.query<
      TrackingEventsIndexApiResponse,
      TrackingEventsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    trackingEventsCreate: build.mutation<
      TrackingEventsCreateApiResponse,
      TrackingEventsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events`,
        method: "POST",
        body: queryArg,
      }),
    }),
    dimensionalFactorsIndex: build.query<
      DimensionalFactorsIndexApiResponse,
      DimensionalFactorsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/dimensional_factors`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    dimensionalFactorsCreate: build.mutation<
      DimensionalFactorsCreateApiResponse,
      DimensionalFactorsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/dimensional_factors`,
        method: "POST",
        body: queryArg,
      }),
    }),
    carriersDelete: build.mutation<
      CarriersDeleteApiResponse,
      CarriersDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg}`,
        method: "DELETE",
      }),
    }),
    carriersShow: build.query<CarriersShowApiResponse, CarriersShowApiArg>({
      query: (queryArg) => ({ url: `/v1/carriers/${queryArg}` }),
    }),
    carriersUpdate: build.mutation<
      CarriersUpdateApiResponse,
      CarriersUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1CarriersById: build.mutation<
      PutV1CarriersByIdApiResponse,
      PutV1CarriersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    extendedAreaLevelsDelete: build.mutation<
      ExtendedAreaLevelsDeleteApiResponse,
      ExtendedAreaLevelsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_levels/${queryArg}`,
        method: "DELETE",
      }),
    }),
    extendedAreaLevelsShow: build.query<
      ExtendedAreaLevelsShowApiResponse,
      ExtendedAreaLevelsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/extended_area_levels/${queryArg}` }),
    }),
    extendedAreaLevelsUpdate: build.mutation<
      ExtendedAreaLevelsUpdateApiResponse,
      ExtendedAreaLevelsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_levels/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ExtendedAreaLevelsById: build.mutation<
      PutV1ExtendedAreaLevelsByIdApiResponse,
      PutV1ExtendedAreaLevelsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_levels/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    rateCardsIndex: build.query<
      RateCardsIndexApiResponse,
      RateCardsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/rate_cards`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    shippersShow: build.query<ShippersShowApiResponse, ShippersShowApiArg>({
      query: (queryArg) => ({ url: `/v1/shippers/${queryArg}` }),
    }),
    shippersUpdate: build.mutation<
      ShippersUpdateApiResponse,
      ShippersUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ShippersById: build.mutation<
      PutV1ShippersByIdApiResponse,
      PutV1ShippersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    accessorialsAccessorialsConfigsDelete: build.mutation<
      AccessorialsAccessorialsConfigsDeleteApiResponse,
      AccessorialsAccessorialsConfigsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/configs/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    accessorialsAccessorialsConfigsShow: build.query<
      AccessorialsAccessorialsConfigsShowApiResponse,
      AccessorialsAccessorialsConfigsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/configs/${queryArg.id}`,
      }),
    }),
    accessorialsAccessorialsConfigsUpdate: build.mutation<
      AccessorialsAccessorialsConfigsUpdateApiResponse,
      AccessorialsAccessorialsConfigsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/configs/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1AccessorialsByAccessorialIdConfigsAndId: build.mutation<
      PutV1AccessorialsByAccessorialIdConfigsAndIdApiResponse,
      PutV1AccessorialsByAccessorialIdConfigsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/configs/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    oversizedSurchargesIndex: build.query<
      OversizedSurchargesIndexApiResponse,
      OversizedSurchargesIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/oversized_surcharges`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    oversizedSurchargesCreate: build.mutation<
      OversizedSurchargesCreateApiResponse,
      OversizedSurchargesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/oversized_surcharges`,
        method: "POST",
        body: queryArg,
      }),
    }),
    serviceLevelsDelete: build.mutation<
      ServiceLevelsDeleteApiResponse,
      ServiceLevelsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/service_levels/${queryArg}`,
        method: "DELETE",
      }),
    }),
    serviceLevelsShow: build.query<
      ServiceLevelsShowApiResponse,
      ServiceLevelsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/service_levels/${queryArg}` }),
    }),
    serviceLevelsUpdate: build.mutation<
      ServiceLevelsUpdateApiResponse,
      ServiceLevelsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/service_levels/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ServiceLevelsById: build.mutation<
      PutV1ServiceLevelsByIdApiResponse,
      PutV1ServiceLevelsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/service_levels/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    extendedAreaLevelsIndex: build.query<
      ExtendedAreaLevelsIndexApiResponse,
      ExtendedAreaLevelsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_levels`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    extendedAreaLevelsCreate: build.mutation<
      ExtendedAreaLevelsCreateApiResponse,
      ExtendedAreaLevelsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_levels`,
        method: "POST",
        body: queryArg,
      }),
    }),
    serviceLevelsIndex: build.query<
      ServiceLevelsIndexApiResponse,
      ServiceLevelsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/service_levels`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    serviceLevelsCreate: build.mutation<
      ServiceLevelsCreateApiResponse,
      ServiceLevelsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/service_levels`,
        method: "POST",
        body: queryArg,
      }),
    }),
    fuelGroupsIndex: build.query<
      FuelGroupsIndexApiResponse,
      FuelGroupsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    fuelGroupsCreate: build.mutation<
      FuelGroupsCreateApiResponse,
      FuelGroupsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups`,
        method: "POST",
        body: queryArg,
      }),
    }),
    deliveryEstimateGetDeliveryEstimate: build.mutation<
      DeliveryEstimateGetDeliveryEstimateApiResponse,
      DeliveryEstimateGetDeliveryEstimateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/delivery_estimate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type ZoneGroupsZonesDeleteApiResponse =
  /** status 204 Successful deletion of a zone */ string;
export type ZoneGroupsZonesDeleteApiArg = {
  /** The zone group id */
  zoneGroupId: string;
  /** The zone id */
  id: string;
};
export type ZoneGroupsZonesShowApiResponse = /** status 200 The zone */ {
  data: Zone;
};
export type ZoneGroupsZonesShowApiArg = {
  /** The zone group id */
  zoneGroupId: string;
  /** The zone id */
  id: string;
};
export type ZoneGroupsZonesUpdateApiResponse =
  /** status 200 An updated zone */ {
    data: Zone;
  };
export type ZoneGroupsZonesUpdateApiArg = {
  /** The zone group id */
  zoneGroupId: string;
  /** The zone id */
  id: string;
  /** Zone update payload */
  body: {
    data: UpdateZone;
  };
};
export type PutV1ZoneGroupsByZoneGroupIdZonesAndIdApiResponse =
  /** status 200 An updated zone */ {
    data: Zone;
  };
export type PutV1ZoneGroupsByZoneGroupIdZonesAndIdApiArg = {
  /** The zone group id */
  zoneGroupId: string;
  /** The zone id */
  id: string;
  /** Zone update payload */
  body: {
    data: UpdateZone;
  };
};
export type ExtendedAreaSurchargesDeleteApiResponse =
  /** status 204 Successful deletion of an extended area surcharge */ string;
export type ExtendedAreaSurchargesDeleteApiArg =
  /** The extended area surcharge id */ string;
export type ExtendedAreaSurchargesShowApiResponse =
  /** status 200 The Extended Area Surcharge */ {
    data: ExtendedAreaSurcharge;
  };
export type ExtendedAreaSurchargesShowApiArg =
  /** The extended area surcharge id */ string;
export type ExtendedAreaSurchargesUpdateApiResponse =
  /** status 200 An updated extended area surcharge */ {
    data: ExtendedAreaSurcharge;
  };
export type ExtendedAreaSurchargesUpdateApiArg = {
  /** The extended area surcharge id */
  id: string;
  /** Extended Area Surcharge update payload */
  body: {
    data: UpdateExtendedAreaSurcharge;
  };
};
export type PutV1ExtendedAreaSurchargesByIdApiResponse =
  /** status 200 An updated extended area surcharge */ {
    data: ExtendedAreaSurcharge;
  };
export type PutV1ExtendedAreaSurchargesByIdApiArg = {
  /** The extended area surcharge id */
  id: string;
  /** Extended Area Surcharge update payload */
  body: {
    data: UpdateExtendedAreaSurcharge;
  };
};
export type CarrierAccountsIndexApiResponse =
  /** status 200 A collection of carrier accounts */ {
    data: ShowCarrierAccount[];
    meta: PaginationMetadata;
  };
export type CarrierAccountsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `carrier_name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_name?: string;
    postal_code?: string;
  };
};
export type CarrierAccountCreateApiResponse =
  /** status 201 The created carrier_account */ {
    data: ShowCarrierAccount;
  };
export type CarrierAccountCreateApiArg =
  /** Carrier Account creation payload */ {
    data: CreateCarrierAccount;
  };
export type ZipZoneMappingsExportApiResponse = unknown;
export type ZipZoneMappingsExportApiArg = {
  /** Sort by `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier?: string | string[] | object;
    carrier_service_method?: string;
    destination_country?: string;
    destination_zip?: string;
    id?: string;
    origin_country?: string;
    origin_zip?: string;
  };
};
export type ShippersAccessorialsIndexApiResponse =
  /** status 200 A collection of shipper accessorials */ {
    data: ShipperAccessorial[];
    meta: PaginationMetadata;
  };
export type ShippersAccessorialsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    accessorial_id?: string;
    shipper_id?: string;
  };
};
export type ShipperCarrierFulfillmentsDeleteApiResponse =
  /** status 204 Successful deletion of a shipper carrier fulfillment context */ string;
export type ShipperCarrierFulfillmentsDeleteApiArg = {
  /** The shipper id */
  shipperId: string;
  /** The carrier account id */
  carrierAccountId: string;
  /** The fulfillment center id */
  fulfillmentCenterId: string;
};
export type ShipperCarrierFulfillmentsCreateApiResponse =
  /** status 201 A shipper carrier fulfillment context */ {
    data: ShipperCarrierFulfillment;
  };
export type ShipperCarrierFulfillmentsCreateApiArg = {
  /** The shipper id */
  shipperId: string;
  /** The carrier account id */
  carrierAccountId: string;
  /** The fulfillment center id */
  fulfillmentCenterId: string;
};
export type CarriersIndexApiResponse =
  /** status 200 A collection of carriers */ {
    data: Carrier[];
    meta: PaginationMetadata;
  };
export type CarriersIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type CarriersCreateApiResponse = /** status 201 The created carrier */ {
  data: Carrier;
};
export type CarriersCreateApiArg = /** Carrier creation payload */ {
  data: CreateCarrier;
};
export type VendorsServiceMethodMappingsDeleteApiResponse =
  /** status 204 Successful deletion of a vendor service method mapping */ string;
export type VendorsServiceMethodMappingsDeleteApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor service method mapping id */
  id: string;
};
export type VendorsServiceMethodMappingsShowApiResponse =
  /** status 200 The vendor service method mapping */ {
    data: VendorServiceMethodMapping;
  };
export type VendorsServiceMethodMappingsShowApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor service method mapping id */
  id: string;
};
export type VendorsServiceMethodMappingsUpdateApiResponse =
  /** status 200 An updated vendor service method mapping */ {
    data: VendorServiceMethodMapping;
  };
export type VendorsServiceMethodMappingsUpdateApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor service method mapping id */
  id: string;
  /** Vendor service method mapping update payload */
  body: {
    data: UpdateVendorServiceMethodMapping;
  };
};
export type PutV1VendorsByVendorIdServiceMethodMappingsAndIdApiResponse =
  /** status 200 An updated vendor service method mapping */ {
    data: VendorServiceMethodMapping;
  };
export type PutV1VendorsByVendorIdServiceMethodMappingsAndIdApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor service method mapping id */
  id: string;
  /** Vendor service method mapping update payload */
  body: {
    data: UpdateVendorServiceMethodMapping;
  };
};
export type ShippersIndexApiResponse =
  /** status 200 A collection of shippers */ {
    data: Shipper[];
    meta: PaginationMetadata;
  };
export type ShippersIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `default_label_provider`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    default_label_provider?: string;
    external_reference?: string;
    name?: string;
  };
};
export type ShippersCreateApiResponse = /** status 201 The shipper */ {
  data: Shipper;
};
export type ShippersCreateApiArg = /** Shipper creation payload */ {
  data: CreateShipper;
};
export type ServiceMethodsIndexApiResponse =
  /** status 200 A collection of service methods */ {
    data: ServiceMethod[];
    meta: PaginationMetadata;
  };
export type ServiceMethodsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_id?: string;
    name?: string;
  };
};
export type AccessorialsDeleteApiResponse =
  /** status 204 Successful deletion of an accessorial */ string;
export type AccessorialsDeleteApiArg = /** The accessorial id */ string;
export type AccessorialsShowApiResponse = /** status 200 The Accessorial */ {
  data: Accessorial;
};
export type AccessorialsShowApiArg = /** The accessorial id */ string;
export type AccessorialsUpdateApiResponse =
  /** status 200 An updated accessorial */ {
    data: Accessorial;
  };
export type AccessorialsUpdateApiArg = {
  /** The accessorial id */
  id: string;
  /** Accessorial update payload */
  body: {
    data: UpdateAccessorial;
  };
};
export type PutV1AccessorialsByIdApiResponse =
  /** status 200 An updated accessorial */ {
    data: Accessorial;
  };
export type PutV1AccessorialsByIdApiArg = {
  /** The accessorial id */
  id: string;
  /** Accessorial update payload */
  body: {
    data: UpdateAccessorial;
  };
};
export type BillingAccountsIndexApiResponse =
  /** status 200 A collection of billing accounts */ {
    data: BillingAccount[];
    meta: PaginationMetadata;
  };
export type BillingAccountsIndexApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    id?: string;
    name?: string;
  };
};
export type BillingAccountCreateApiResponse =
  /** status 201 The created billing account */ {
    data: BillingAccount;
  };
export type BillingAccountCreateApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Billing Account creation payload */
  body: {
    data: CreateBillingAccount;
  };
};
export type FulfillmentCentersIndexApiResponse =
  /** status 200 A collection of fulfillment centers */ {
    data: FulfillmentCenter[];
    meta: PaginationMetadata;
  };
export type FulfillmentCentersIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
  /** Search by the following fields: name, alias */
  search?: string;
};
export type FulfillmentCentersCreateApiResponse =
  /** status 201 The created fulfillment_center */ {
    data: FulfillmentCenter;
  };
export type FulfillmentCentersCreateApiArg =
  /** Fulfillment center creation payload */ {
    data: CreateFulfillmentCenter;
  };
export type VendorsCarrierMappingsIndexApiResponse =
  /** status 200 A collection of vendor carrier mappings */ {
    data: VendorCarrierMapping[];
    meta: PaginationMetadata;
  };
export type VendorsCarrierMappingsIndexApiArg = {
  /** The vendor id */
  vendorId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `vendor_carrier`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    vendor_carrier?: string;
  };
};
export type VendorsCarrierMappingsCreateApiResponse =
  /** status 201 The created vendor carrier mapping */ {
    data: VendorCarrierMapping;
  };
export type VendorsCarrierMappingsCreateApiArg = {
  /** The vendor id */
  vendorId: string;
  /** Vendor carrier mapping creation payload */
  body: {
    data: CreateVendorCarrierMapping;
  };
};
export type ShippersRateCardsIndexApiResponse =
  /** status 200 A collection of rate cards */ {
    data: RateCard[];
    meta: PaginationMetadata;
  };
export type ShippersRateCardsIndexApiArg = {
  /** The shipper id */
  shipperId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `rate_type`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    fulfillment_center_id?: string;
    rate_type?: "buy" | "sell";
    service_level_id?: string;
    service_method_id?: string;
  };
};
export type ShippersRateCardsCreateApiResponse =
  /** status 201 The created rate card */ {
    data: RateCard;
  };
export type ShippersRateCardsCreateApiArg = {
  /** The shipper id */
  shipperId: string;
  /** Rate card creation payload */
  body: {
    data: CreateRateCard;
  };
};
export type RegistrationsDeleteApiResponse =
  /** status 204 Successful deletion of a registration */ string;
export type RegistrationsDeleteApiArg = /** The registration id */ string;
export type RegistrationsShowApiResponse = /** status 200 The Registration */ {
  data: ShowRegistration;
};
export type RegistrationsShowApiArg = /** The registration id */ string;
export type RegistrationsUpdateApiResponse =
  /** status 200 An updated registration */ ShowRegistration;
export type RegistrationsUpdateApiArg = {
  /** The registration id */
  id: string;
  /** Registration update payload */
  updateRegistration: UpdateRegistration;
};
export type PutV1RegistrationsByIdApiResponse =
  /** status 200 An updated registration */ ShowRegistration;
export type PutV1RegistrationsByIdApiArg = {
  /** The registration id */
  id: string;
  /** Registration update payload */
  updateRegistration: UpdateRegistration;
};
export type FulfillmentCentersDeleteApiResponse =
  /** status 204 Successful deletion of a fulfillment center */ string;
export type FulfillmentCentersDeleteApiArg =
  /** The fulfillment center id */ string;
export type FulfillmentCentersShowApiResponse =
  /** status 200 The Fulfillment Center */ {
    data: FulfillmentCenter;
  };
export type FulfillmentCentersShowApiArg =
  /** The fulfillment center id */ string;
export type FulfillmentCentersUpdateApiResponse =
  /** status 200 An updated fulfillment center */ {
    data: FulfillmentCenter;
  };
export type FulfillmentCentersUpdateApiArg = {
  /** The fulfillment id */
  id: string;
  /** Fulfillment center update payload */
  body: {
    data: UpdateFulfillmentCenter;
  };
};
export type PutV1FulfillmentCentersByIdApiResponse =
  /** status 200 An updated fulfillment center */ {
    data: FulfillmentCenter;
  };
export type PutV1FulfillmentCentersByIdApiArg = {
  /** The fulfillment id */
  id: string;
  /** Fulfillment center update payload */
  body: {
    data: UpdateFulfillmentCenter;
  };
};
export type AccessorialsShipperAccessorialsIndexApiResponse =
  /** status 200 A collection of shipper accessorials */ {
    data: ShipperAccessorial[];
    meta: PaginationMetadata;
  };
export type AccessorialsShipperAccessorialsIndexApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    shipper_id?: string;
  };
};
export type AccessorialsShippersAccessorialsCreateApiResponse =
  /** status 201 The created shipper accessorial */ {
    data: ShipperAccessorial;
  };
export type AccessorialsShippersAccessorialsCreateApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** Shipper accessorial creation payload */
  body: {
    data: CreateShipperAccessorial;
  };
};
export type BillingAccountsDeleteApiResponse =
  /** status 204 Successful deletion of a billing account */ string;
export type BillingAccountsDeleteApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The billing account id */
  id: string;
};
export type BillingAccountsShowApiResponse =
  /** status 200 The Billing Account */ {
    data: BillingAccount;
  };
export type BillingAccountsShowApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The billing account id */
  id: string;
};
export type BillingAccountsUpdateApiResponse =
  /** status 200 An updated billing account */ {
    data: BillingAccount;
  };
export type BillingAccountsUpdateApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The billing account id */
  id: string;
  /** Billing Account update payload */
  body: {
    data: UpdateBillingAccount;
  };
};
export type PutV1ShippersByShipperIdBillingAccountsAndIdApiResponse =
  /** status 200 An updated billing account */ {
    data: BillingAccount;
  };
export type PutV1ShippersByShipperIdBillingAccountsAndIdApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The billing account id */
  id: string;
  /** Billing Account update payload */
  body: {
    data: UpdateBillingAccount;
  };
};
export type TrackingEventsDeleteApiResponse =
  /** status 204 Successful deletion of a tracking event */ string;
export type TrackingEventsDeleteApiArg = /** The tracking event id */ string;
export type TrackingEventsShowApiResponse =
  /** status 200 The Tracking Event */ {
    data: ShowTrackingEvent;
  };
export type TrackingEventsShowApiArg = /** The tracking event id */ string;
export type TrackingEventsUpdateApiResponse =
  /** status 200 An updated tracking event */ {
    data: ShowTrackingEvent;
  };
export type TrackingEventsUpdateApiArg = {
  /** The tracking event id */
  trackingEventId: string;
  /** Tracking Event update payload */
  body: {
    data: UpdateTrackingEvent;
  };
};
export type PutV1TrackingEventsByTrackingEventIdApiResponse =
  /** status 200 An updated tracking event */ {
    data: ShowTrackingEvent;
  };
export type PutV1TrackingEventsByTrackingEventIdApiArg = {
  /** The tracking event id */
  trackingEventId: string;
  /** Tracking Event update payload */
  body: {
    data: UpdateTrackingEvent;
  };
};
export type VendorsDeleteApiResponse =
  /** status 204 Successful deletion of a vendor */ string;
export type VendorsDeleteApiArg = /** The vendor id */ string;
export type VendorsShowApiResponse = /** status 200 The Vendor */ {
  data: Vendor;
};
export type VendorsShowApiArg = /** The vendor id */ string;
export type VendorsUpdateApiResponse = /** status 200 An updated vendor */ {
  data: Vendor;
};
export type VendorsUpdateApiArg = {
  /** The vendor id */
  id: string;
  /** Vendor update payload */
  body: {
    data: UpdateVendor;
  };
};
export type PutV1VendorsByIdApiResponse = /** status 200 An updated vendor */ {
  data: Vendor;
};
export type PutV1VendorsByIdApiArg = {
  /** The vendor id */
  id: string;
  /** Vendor update payload */
  body: {
    data: UpdateVendor;
  };
};
export type ReturnLabelsCreateApiResponse =
  /** status 201 The created return label */ {
    data: ShowParcel;
  };
export type ReturnLabelsCreateApiArg = {
  /** The shipper associated with the return label */
  shipperId: string;
  /** Return label creation payload */
  body: {
    data: CreateReturnLabel;
  };
};
export type OversizedSurchargesDeleteApiResponse =
  /** status 204 Successful deletion of an oversized surcharge */ string;
export type OversizedSurchargesDeleteApiArg =
  /** The oversized surcharge id */ string;
export type OversizedSurchargesShowApiResponse =
  /** status 200 The oversized surcharge */ {
    data: OversizedSurcharge;
  };
export type OversizedSurchargesShowApiArg =
  /** The oversized surcharge id */ string;
export type OversizedSurchargesUpdateApiResponse =
  /** status 200 An updated oversized surcharge */ {
    data: OversizedSurcharge;
  };
export type OversizedSurchargesUpdateApiArg = {
  /** The oversized surcharge id */
  id: string;
  /** Oversized surcharge update payload */
  body: {
    data: UpdateOversizedSurcharge;
  };
};
export type PutV1OversizedSurchargesByIdApiResponse =
  /** status 200 An updated oversized surcharge */ {
    data: OversizedSurcharge;
  };
export type PutV1OversizedSurchargesByIdApiArg = {
  /** The oversized surcharge id */
  id: string;
  /** Oversized surcharge update payload */
  body: {
    data: UpdateOversizedSurcharge;
  };
};
export type DimensionalFactorsDeleteApiResponse =
  /** status 204 Successful deletion of a dimensional factor */ string;
export type DimensionalFactorsDeleteApiArg =
  /** The dimensional factor id */ string;
export type DimensionalFactorsShowApiResponse =
  /** status 200 The Dimensional Factor */ {
    data: DimensionalFactor;
  };
export type DimensionalFactorsShowApiArg =
  /** The dimensional factor id */ string;
export type DimensionalFactorsUpdateApiResponse =
  /** status 200 An updated dimensional factor */ {
    data: DimensionalFactor;
  };
export type DimensionalFactorsUpdateApiArg = {
  /** The dimensional factor id */
  id: string;
  /** Dimensional Factor update payload */
  body: {
    data: UpdateDimensionalFactor;
  };
};
export type PutV1DimensionalFactorsByIdApiResponse =
  /** status 200 An updated dimensional factor */ {
    data: DimensionalFactor;
  };
export type PutV1DimensionalFactorsByIdApiArg = {
  /** The dimensional factor id */
  id: string;
  /** Dimensional Factor update payload */
  body: {
    data: UpdateDimensionalFactor;
  };
};
export type ParcelRateEstimateApiResponse =
  /** status 200 A collection parcel rate estimates */ {
    data: ShowRates[];
  };
export type ParcelRateEstimateApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** parcel rate payload */
  body: {
    data: RateEstimate;
  };
};
export type ParcelsIndexApiResponse =
  /** status 200 A collection of parcels */ {
    data: ShowParcel[];
    meta: PaginationMetadata;
  };
export type ParcelsIndexApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `carrier_name`, `label_provider`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_name?: string;
    external_source_id?: string;
    label_provider?: string;
    tracking_number?: string;
  };
};
export type ParcelCreateApiResponse =
  /** status 201 The created parcel */
  | {
      data: ShowParcel;
    }
  | /** status 202 Parcels Batch: Accepted */ {
      data?: {
        attributes: {
          status: string;
        };
        id: string;
        links?: {
          self?: string;
        };
        type: string;
      };
    };
export type ParcelCreateApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Parcel creation payload */
  body:
    | {
        data: CreateParcel;
      }
    | {
        data: CreateParcelBatch;
      };
};
export type ZipZoneMappingsIndexApiResponse =
  /** status 200 A collection of zip zone mappings */ {
    data: ZipZoneMapping[];
    meta: PaginationMetadata;
  };
export type ZipZoneMappingsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier?: string | string[] | object;
    carrier_service_method?: string;
    destination_country?: string;
    destination_zip?: string;
    id?: string;
    origin_country?: string;
    origin_zip?: string;
  };
};
export type ZipZoneMappingsUpsertApiResponse =
  /** status 201 A collection of the zip zone mappings */ {
    data: ZipZoneMapping[];
  };
export type ZipZoneMappingsUpsertApiArg =
  /** Payload to upsert zip zone mappings */ {
    data: CreateZipZoneMapping[];
  };
export type AccessorialsIndexApiResponse =
  /** status 200 A collection of accessorials */ {
    data: Accessorial[];
    meta: PaginationMetadata;
  };
export type AccessorialsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type AccessorialsCreateApiResponse =
  /** status 201 The created accessorial */ {
    data: Accessorial;
  };
export type AccessorialsCreateApiArg = /** Accessorial creation payload */ {
  data: CreateAccessorial;
};
export type ExtendedAreasDeleteApiResponse =
  /** status 204 Successful deletion of a extended area */ string;
export type ExtendedAreasDeleteApiArg = /** The extended area id */ string;
export type ExtendedAreasShowApiResponse = /** status 200 The Extended Area */ {
  data: ExtendedArea;
};
export type ExtendedAreasShowApiArg = /** The extended area id */ string;
export type ExtendedAreasUpdateApiResponse =
  /** status 200 An updated extended area */ {
    data: ExtendedArea;
  };
export type ExtendedAreasUpdateApiArg = {
  /** The extended area id */
  id: string;
  /** Extended Area update payload */
  body: {
    data: UpdateExtendedArea;
  };
};
export type PutV1ExtendedAreasByIdApiResponse =
  /** status 200 An updated extended area */ {
    data: ExtendedArea;
  };
export type PutV1ExtendedAreasByIdApiArg = {
  /** The extended area id */
  id: string;
  /** Extended Area update payload */
  body: {
    data: UpdateExtendedArea;
  };
};
export type VendorsServiceMethodMappingsIndexApiResponse =
  /** status 200 A collection of vendor service method mappings */ {
    data: VendorServiceMethodMapping[];
    meta: PaginationMetadata;
  };
export type VendorsServiceMethodMappingsIndexApiArg = {
  /** The vendor id */
  vendorId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `vendor_service_method`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    vendor_service_method?: string;
  };
};
export type VendorsServiceMethodMappingsCreateApiResponse =
  /** status 201 The created vendor service method mapping */ {
    data: VendorServiceMethodMapping;
  };
export type VendorsServiceMethodMappingsCreateApiArg = {
  /** The vendor id */
  vendorId: string;
  /** Vendor service method mapping creation payload */
  body: {
    data: CreateVendorServiceMethodMapping;
  };
};
export type CarrierAccountsDeleteApiResponse =
  /** status 204 Successful deletion of a carrier account */ string;
export type CarrierAccountsDeleteApiArg = /** The carrier account id */ string;
export type CarrierAccountsShowApiResponse =
  /** status 200 The Carrier Account */ {
    data: ShowCarrierAccount;
  };
export type CarrierAccountsShowApiArg = /** The carrier account id */ string;
export type CarrierAccountsUpdateApiResponse =
  /** status 200 An updated carrier account */ {
    data: ShowCarrierAccount;
  };
export type CarrierAccountsUpdateApiArg = {
  /** The carrier account id */
  id: string;
  /** Carrier Account update payload */
  body: {
    data: UpdateCarrierAccount;
  };
};
export type PutV1CarrierAccountsByIdApiResponse =
  /** status 200 An updated carrier account */ {
    data: ShowCarrierAccount;
  };
export type PutV1CarrierAccountsByIdApiArg = {
  /** The carrier account id */
  id: string;
  /** Carrier Account update payload */
  body: {
    data: UpdateCarrierAccount;
  };
};
export type AccessorialsCarrierAccessorialsDeleteApiResponse =
  /** status 204 Successful deletion of a carrier accessorial */ string;
export type AccessorialsCarrierAccessorialsDeleteApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The carrier accessorial id */
  id: string;
};
export type AccessorialsCarrierAccessorialsShowApiResponse =
  /** status 200 The carrier accessorial */ {
    data: CarrierAccessorial;
  };
export type AccessorialsCarrierAccessorialsShowApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The carrier accessorial id */
  id: string;
};
export type AccessorialsCarrierAccessorialsUpdateApiResponse =
  /** status 200 An updated carrier accessorial */ {
    data: CarrierAccessorial;
  };
export type AccessorialsCarrierAccessorialsUpdateApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The carrier accessorial id */
  id: string;
  /** Carrier accessorial update payload */
  body: {
    data: UpdateCarrierAccessorial;
  };
};
export type PutV1AccessorialsByAccessorialIdCarrierAccessorialsAndIdApiResponse =
  /** status 200 An updated carrier accessorial */ {
    data: CarrierAccessorial;
  };
export type PutV1AccessorialsByAccessorialIdCarrierAccessorialsAndIdApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The carrier accessorial id */
  id: string;
  /** Carrier accessorial update payload */
  body: {
    data: UpdateCarrierAccessorial;
  };
};
export type ParcelsRerateApiResponse = /** status 200 The rating */ {
  data: Rating;
};
export type ParcelsRerateApiArg = {
  /** The parcel id */
  parcelId: string;
  /** Rerating payload */
  body: {
    data: RerateRequest;
  };
};
export type ExtendedAreasIndexApiResponse =
  /** status 200 A collection of extended areas */ {
    data: ExtendedArea[];
    meta: PaginationMetadata;
  };
export type ExtendedAreasIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `postal_code`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    postal_code?: string;
  };
};
export type ExtendedAreaCreateApiResponse =
  /** status 201 The created extended area */ {
    data: ExtendedArea;
  };
export type ExtendedAreaCreateApiArg = /** Extended Area creation payload */ {
  data: CreateExtendedArea;
};
export type VendorsCarrierMappingsDeleteApiResponse =
  /** status 204 Successful deletion of a vendor carrier mapping */ string;
export type VendorsCarrierMappingsDeleteApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor carrier mapping id */
  id: string;
};
export type VendorsCarrierMappingsShowApiResponse =
  /** status 200 The vendor carrier mapping */ {
    data: VendorCarrierMapping;
  };
export type VendorsCarrierMappingsShowApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor carrier mapping id */
  id: string;
};
export type VendorsCarrierMappingsUpdateApiResponse =
  /** status 200 An updated vendor carrier mapping */ {
    data: VendorCarrierMapping;
  };
export type VendorsCarrierMappingsUpdateApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor carrier mapping id */
  id: string;
  /** Vendor carrier mapping update payload */
  body: {
    data: UpdateVendorCarrierMapping;
  };
};
export type PutV1VendorsByVendorIdCarrierMappingsAndIdApiResponse =
  /** status 200 An updated vendor carrier mapping */ {
    data: VendorCarrierMapping;
  };
export type PutV1VendorsByVendorIdCarrierMappingsAndIdApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor carrier mapping id */
  id: string;
  /** Vendor carrier mapping update payload */
  body: {
    data: UpdateVendorCarrierMapping;
  };
};
export type AccessorialsCarrierAccessorialsIndexApiResponse =
  /** status 200 A collection of carrier accessorials */ {
    data: CarrierAccessorial[];
    meta: PaginationMetadata;
  };
export type AccessorialsCarrierAccessorialsIndexApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_id?: string;
    name?: string;
  };
};
export type AccessorialsCarrierAccessorialsCreateApiResponse =
  /** status 201 The created carrier accessorial */ {
    data: CarrierAccessorial;
  };
export type AccessorialsCarrierAccessorialsCreateApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** Carrier accessorial creation payload */
  body: {
    data: CreateCarrierAccessorial;
  };
};
export type ShippersRateCardsDeleteApiResponse =
  /** status 204 Successful deletion of a rate card */ string;
export type ShippersRateCardsDeleteApiArg = {
  /** The shipper id */
  shipperId: string;
  /** The rate card id */
  id: string;
};
export type ShippersRateCardsShowApiResponse = /** status 200 The Rate Card */ {
  data: RateCard;
};
export type ShippersRateCardsShowApiArg = {
  /** The shipper id */
  shipperId: string;
  /** The rate card id */
  id: string;
};
export type ShippersRateCardsUpdateApiResponse =
  /** status 200 An updated rate card */ {
    data: RateCard;
  };
export type ShippersRateCardsUpdateApiArg = {
  /** The shipper id */
  shipperId: string;
  /** The rate card id */
  id: string;
  /** Rate card update payload */
  body: {
    data: UpdateRateCard;
  };
};
export type PutV1ShippersByShipperIdRateCardsAndIdApiResponse =
  /** status 200 An updated rate card */ {
    data: RateCard;
  };
export type PutV1ShippersByShipperIdRateCardsAndIdApiArg = {
  /** The shipper id */
  shipperId: string;
  /** The rate card id */
  id: string;
  /** Rate card update payload */
  body: {
    data: UpdateRateCard;
  };
};
export type ZoneGroupsIndexApiResponse =
  /** status 200 A collection of zone groups */ {
    data: ZoneGroup[];
    meta: PaginationMetadata;
  };
export type ZoneGroupsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_id?: string;
    origin_country?: string;
    origin_zip?: string;
    rate_type?: "buy" | "sell";
    service_level_id?: string;
    service_method_id?: string;
  };
};
export type ZoneGroupsCreateApiResponse =
  /** status 201 The created zone group */ {
    data: ZoneGroup;
  };
export type ZoneGroupsCreateApiArg = /** Zone group creation payload */ {
  data: CreateZoneGroup;
};
export type ParcelVoidApiResponse =
  /** status 204 Successful deletion of a parcel */ string;
export type ParcelVoidApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The parcel id */
  id: string;
};
export type ParcelShowApiResponse = /** status 200 The Parcel */ {
  data: ShowParcel;
};
export type ParcelShowApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The parcel id */
  id: string;
};
export type CarriersServiceMethodsDeleteApiResponse =
  /** status 204 Successful deletion of a service method */ string;
export type CarriersServiceMethodsDeleteApiArg = {
  /** The carrier id */
  carrierId: string;
  /** The service method id */
  id: string;
};
export type CarriersServiceMethodsShowApiResponse =
  /** status 200 The service method */ {
    data: ServiceMethod;
  };
export type CarriersServiceMethodsShowApiArg = {
  /** The carrier id */
  carrierId: string;
  /** The service method id */
  id: string;
};
export type CarriersServiceMethodsUpdateApiResponse =
  /** status 200 An updated service method */ {
    data: ServiceMethod;
  };
export type CarriersServiceMethodsUpdateApiArg = {
  /** The carrier id */
  carrierId: string;
  /** The service method id */
  id: string;
  /** Service method update payload */
  body: {
    data: UpdateServiceMethod;
  };
};
export type PutV1CarriersByCarrierIdServiceMethodsAndIdApiResponse =
  /** status 200 An updated service method */ {
    data: ServiceMethod;
  };
export type PutV1CarriersByCarrierIdServiceMethodsAndIdApiArg = {
  /** The carrier id */
  carrierId: string;
  /** The service method id */
  id: string;
  /** Service method update payload */
  body: {
    data: UpdateServiceMethod;
  };
};
export type CarrierAccessorialsIndexApiResponse =
  /** status 200 A collection of carrier accessorials */ {
    data: CarrierAccessorial[];
    meta: PaginationMetadata;
  };
export type CarrierAccessorialsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    accessorial_id?: string;
    carrier_id?: string;
    name?: string;
  };
};
export type FuelGroupsFuelSurchargesIndexApiResponse =
  /** status 200 A collection of fuel surcharges */ {
    data: FuelSurcharge[];
    meta: PaginationMetadata;
  };
export type FuelGroupsFuelSurchargesIndexApiArg = {
  /** The fuel group id */
  fuelGroupId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `fuel_percent`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
};
export type FuelGroupsFuelSurchargesCreateApiResponse =
  /** status 201 The created carrier accessorial */ {
    data: FuelSurcharge;
  };
export type FuelGroupsFuelSurchargesCreateApiArg = {
  /** The fuel group id */
  fuelGroupId: string;
  /** Fuel surcharge creation payload */
  body: {
    data: CreateFuelSurcharge;
  };
};
export type RegistrationsIndexApiResponse =
  /** status 200 A collection of registrations */ {
    data: Registration[];
    meta: PaginationMetadata;
  };
export type RegistrationsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `delivered_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type RegistrationsCreateApiResponse =
  /** status 201 The created registrations */ {
    data: Registration;
  };
export type RegistrationsCreateApiArg = /** Registration creation payload */ {
  data: Registration;
};
export type FuelGroupsFuelSurchargesDeleteApiResponse =
  /** status 204 Successful deletion of a fuel surcharge */ string;
export type FuelGroupsFuelSurchargesDeleteApiArg = {
  /** The fuel group id */
  fuelGroupId: string;
  /** The fuel surcharge id */
  id: string;
};
export type FuelGroupsFuelSurchargesShowApiResponse =
  /** status 200 The fuel surcharge */ {
    data: FuelSurcharge;
  };
export type FuelGroupsFuelSurchargesShowApiArg = {
  /** The fuel group id */
  fuelGroupId: string;
  /** The fuel surcharge id */
  id: string;
};
export type FuelGroupsFuelSurchargesUpdateApiResponse =
  /** status 200 An updated fuel surcharge */ {
    data: FuelSurcharge;
  };
export type FuelGroupsFuelSurchargesUpdateApiArg = {
  /** The fuel group id */
  fuelGroupId: string;
  /** The fuel surcharge id */
  id: string;
  /** Carrier accessorial update payload */
  body: {
    data: UpdateFuelSurcharge;
  };
};
export type PutV1FuelGroupsByFuelGroupIdFuelSurchargesAndIdApiResponse =
  /** status 200 An updated fuel surcharge */ {
    data: FuelSurcharge;
  };
export type PutV1FuelGroupsByFuelGroupIdFuelSurchargesAndIdApiArg = {
  /** The fuel group id */
  fuelGroupId: string;
  /** The fuel surcharge id */
  id: string;
  /** Carrier accessorial update payload */
  body: {
    data: UpdateFuelSurcharge;
  };
};
export type ZoneGroupsDeleteApiResponse =
  /** status 204 Successful deletion of a zone group */ string;
export type ZoneGroupsDeleteApiArg = /** The zone group id */ string;
export type ZoneGroupsShowApiResponse = /** status 200 The zone group */ {
  data: ZoneGroup;
};
export type ZoneGroupsShowApiArg = /** The zone group id */ string;
export type ZoneGroupsUpdateApiResponse =
  /** status 200 An updated zone group */ {
    data: ZoneGroup;
  };
export type ZoneGroupsUpdateApiArg = {
  /** The zone group id */
  id: string;
  /** Zone group update payload */
  body: {
    data: UpdateZoneGroup;
  };
};
export type PutV1ZoneGroupsByIdApiResponse =
  /** status 200 An updated zone group */ {
    data: ZoneGroup;
  };
export type PutV1ZoneGroupsByIdApiArg = {
  /** The zone group id */
  id: string;
  /** Zone group update payload */
  body: {
    data: UpdateZoneGroup;
  };
};
export type ShippersRateCardsRateRecordsIndexApiResponse =
  /** status 200 A collection of rate records */ {
    data: RateRecord[];
    meta: PaginationMetadata;
  };
export type ShippersRateCardsRateRecordsIndexApiArg = {
  /** The shipper id */
  shipperId: string;
  /** The rate card id */
  rateCardId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `rating_type`, `flat_rate`, `variable_rate`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    rating_type?: "flat" | "variable" | "hybrid";
    zone_code?: string;
  };
};
export type ShippersRateCardsRateRecordsCreateApiResponse =
  /** status 201 The created rate record */ {
    data: RateRecord;
  };
export type ShippersRateCardsRateRecordsCreateApiArg = {
  /** The shipper id */
  shipperId: string;
  /** The rate card id */
  rateCardId: string;
  /** Rate record creation payload */
  body: {
    data: CreateRateRecord;
  };
};
export type AccessorialsShipperAccessorialsDeleteApiResponse =
  /** status 204 Successful deletion of a shipper accessorial */ string;
export type AccessorialsShipperAccessorialsDeleteApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The shipper accessorial id */
  id: string;
};
export type AccessorialsShipperAccessorialsShowApiResponse =
  /** status 200 The shipper accessorial */ {
    data: ShipperAccessorial;
  };
export type AccessorialsShipperAccessorialsShowApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The shipper accessorial id */
  id: string;
};
export type AccessorialsShipperAccessorialsUpdateApiResponse =
  /** status 200 An updated shipper accessorial */ {
    data: ShipperAccessorial;
  };
export type AccessorialsShipperAccessorialsUpdateApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The shipper accessorial id */
  id: string;
  /** Shipper accessorial update payload */
  body: {
    data: UpdateShipperAccessorial;
  };
};
export type PutV1AccessorialsByAccessorialIdShipperAccessorialsAndIdApiResponse =
  /** status 200 An updated shipper accessorial */ {
    data: ShipperAccessorial;
  };
export type PutV1AccessorialsByAccessorialIdShipperAccessorialsAndIdApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The shipper accessorial id */
  id: string;
  /** Shipper accessorial update payload */
  body: {
    data: UpdateShipperAccessorial;
  };
};
export type AccessorialsConfigsIndexApiResponse =
  /** status 200 A collection of accessorial configs */ {
    data: AccessorialConfig[];
    meta: PaginationMetadata;
  };
export type AccessorialsConfigsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `run_type`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    accessorial_id?: string;
    run_type?: string;
  };
};
export type CarriersServiceMethodsIndexApiResponse =
  /** status 200 A collection of service methods */ {
    data: ServiceMethod[];
    meta: PaginationMetadata;
  };
export type CarriersServiceMethodsIndexApiArg = {
  /** The carrier id */
  carrierId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type CarrierServiceMethodsCreateApiResponse =
  /** status 201 The created carrier service method */ {
    data: ServiceMethod;
  };
export type CarrierServiceMethodsCreateApiArg = {
  /** The carrier id */
  carrierId: string;
  /** Service method creation payload */
  body: {
    data: CreateServiceMethod;
  };
};
export type ParcelBatchShowApiResponse =
  /** status 200 A collection of batch jobs */ {
    data: BatchJob[];
  };
export type ParcelBatchShowApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The batch id */
  batchId: string;
};
export type EasyPostWebhookEventApiResponse =
  /** status 202 Acknowledge the webhook */ string;
export type EasyPostWebhookEventApiArg =
  /** Webhook tracking event payload */ WebhookEvent;
export type ShippersRateCardsRateRecordsDeleteApiResponse =
  /** status 204 Successful deletion of a rate record */ string;
export type ShippersRateCardsRateRecordsDeleteApiArg = {
  /** The shipper id */
  shipperId: string;
  /** The rate card id */
  rateCardId: string;
  /** The rate record id */
  id: string;
};
export type ShippersRateCardsRateRecordsShowApiResponse =
  /** status 200 The rate record */ {
    data: RateRecord;
  };
export type ShippersRateCardsRateRecordsShowApiArg = {
  /** The shipper id */
  shipperId: string;
  /** The rate card id */
  rateCardId: string;
  /** The rate record id */
  id: string;
};
export type ShippersRateCardsRateRecordsUpdateApiResponse =
  /** status 200 An updated rate record */ {
    data: RateRecord;
  };
export type ShippersRateCardsRateRecordsUpdateApiArg = {
  /** The shipper id */
  shipperId: string;
  /** The rate card id */
  rateCardId: string;
  /** The rate record id */
  id: string;
  /** Rate record update payload */
  body: {
    data: UpdateRateRecord;
  };
};
export type PutV1ShippersByShipperIdRateCardsAndRateCardIdRateRecordsIdApiResponse =
  /** status 200 An updated rate record */ {
    data: RateRecord;
  };
export type PutV1ShippersByShipperIdRateCardsAndRateCardIdRateRecordsIdApiArg =
  {
    /** The shipper id */
    shipperId: string;
    /** The rate card id */
    rateCardId: string;
    /** The rate record id */
    id: string;
    /** Rate record update payload */
    body: {
      data: UpdateRateRecord;
    };
  };
export type ZoneGroupsZonesIndexApiResponse =
  /** status 200 A collection of zones */ {
    data: Zone[];
    meta: PaginationMetadata;
  };
export type ZoneGroupsZonesIndexApiArg = {
  /** The zone group id */
  zoneGroupId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `zone_code`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    destination_country?: string;
    destination_zip?: string;
    zone_code?: string;
  };
};
export type ZoneGroupsZonesCreateApiResponse =
  /** status 201 The created zone */ {
    data: Zone;
  };
export type ZoneGroupsZonesCreateApiArg = {
  /** The zone group id */
  zoneGroupId: string;
  /** Zone creation payload */
  body: {
    data: CreateZone;
  };
};
export type AccessorialsAccessorialsConfigsIndexApiResponse =
  /** status 200 A collection of accessorial configs */ {
    data: AccessorialConfig[];
    meta: PaginationMetadata;
  };
export type AccessorialsAccessorialsConfigsIndexApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `run_type`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    run_type?: string;
  };
};
export type AccessorialsAccessorialsConfigsCreateApiResponse =
  /** status 201 The created accessorial config */ {
    data: AccessorialConfig;
  };
export type AccessorialsAccessorialsConfigsCreateApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** Accessorial config creation payload */
  body: {
    data: CreateAccessorialConfig;
  };
};
export type FuelGroupsDeleteApiResponse =
  /** status 204 Successful deletion of a fuel group */ string;
export type FuelGroupsDeleteApiArg = /** The fuel group id */ string;
export type FuelGroupsShowApiResponse = /** status 200 The Fuel Group */ {
  data: FuelGroup;
};
export type FuelGroupsShowApiArg = /** The fuel group id */ string;
export type FuelGroupsUpdateApiResponse =
  /** status 200 An updated fuel group */ {
    data: FuelGroup;
  };
export type FuelGroupsUpdateApiArg = {
  /** The fuel group id */
  id: string;
  /** Fuel Group update payload */
  body: {
    data: UpdateFuelGroup;
  };
};
export type PutV1FuelGroupsByIdApiResponse =
  /** status 200 An updated fuel group */ {
    data: FuelGroup;
  };
export type PutV1FuelGroupsByIdApiArg = {
  /** The fuel group id */
  id: string;
  /** Fuel Group update payload */
  body: {
    data: UpdateFuelGroup;
  };
};
export type VendorsIndexApiResponse =
  /** status 200 A collection of vendors */ {
    data: Vendor[];
    meta: PaginationMetadata;
  };
export type VendorsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type VendorsCreateApiResponse = /** status 201 The created vendor */ {
  data: Vendor;
};
export type VendorsCreateApiArg = /** Vendor creation payload */ {
  data: CreateVendor;
};
export type ExtendedAreaSurchargesIndexApiResponse =
  /** status 200 A collection of extended area surcharges */ {
    data: ExtendedAreaSurcharge[];
    meta: PaginationMetadata;
  };
export type ExtendedAreaSurchargesIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
};
export type ExtendedAreaSurchargesCreateApiResponse =
  /** status 201 The created extended area surcharge */ {
    data: ExtendedAreaSurcharge;
  };
export type ExtendedAreaSurchargesCreateApiArg =
  /** Extended Area Surcharge creation payload */ {
    data: CreateExtendedAreaSurcharge;
  };
export type ShipiumWebhookEventApiResponse =
  /** status 202 Acknowledge the webhook */ string;
export type ShipiumWebhookEventApiArg =
  /** Webhook tracking event payload */ ShipiumWebhookEvent;
export type TrackingEventsIndexApiResponse =
  /** status 200 A collection of tracking events */ {
    data: TrackingEvent[];
    meta: PaginationMetadata;
  };
export type TrackingEventsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `occurred_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type TrackingEventsCreateApiResponse =
  /** status 201 The created tracking_events */ {
    data: TrackingEvent;
  };
export type TrackingEventsCreateApiArg =
  /** Tracking event creation payload */ {
    data: TrackingEvent;
  };
export type DimensionalFactorsIndexApiResponse =
  /** status 200 A collection of dimensional factors */ {
    data: DimensionalFactor[];
    meta: PaginationMetadata;
  };
export type DimensionalFactorsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `factor`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_id?: string;
    service_level_id?: string;
    service_method_id?: string;
    shipper_id?: string;
  };
};
export type DimensionalFactorsCreateApiResponse =
  /** status 201 The created dimensional factor */ {
    data: DimensionalFactor;
  };
export type DimensionalFactorsCreateApiArg =
  /** Dimensional factor creation payload */ {
    data: CreateDimensionalFactor;
  };
export type CarriersDeleteApiResponse =
  /** status 204 Successful deletion of a carrier */ string;
export type CarriersDeleteApiArg = /** The carrier id */ string;
export type CarriersShowApiResponse = /** status 200 The Carrier */ {
  data: Carrier;
};
export type CarriersShowApiArg = /** The carrier id */ string;
export type CarriersUpdateApiResponse = /** status 200 An updated carrier */ {
  data: Carrier;
};
export type CarriersUpdateApiArg = {
  /** The carrier id */
  id: string;
  /** Carrier update payload */
  body: {
    data: UpdateCarrier;
  };
};
export type PutV1CarriersByIdApiResponse =
  /** status 200 An updated carrier */ {
    data: Carrier;
  };
export type PutV1CarriersByIdApiArg = {
  /** The carrier id */
  id: string;
  /** Carrier update payload */
  body: {
    data: UpdateCarrier;
  };
};
export type ExtendedAreaLevelsDeleteApiResponse =
  /** status 204 Successful deletion of a extended area level */ string;
export type ExtendedAreaLevelsDeleteApiArg =
  /** The extended area level id */ string;
export type ExtendedAreaLevelsShowApiResponse =
  /** status 200 The Extended Area Level */ {
    data: ExtendedAreaLevel;
  };
export type ExtendedAreaLevelsShowApiArg =
  /** The extended area level id */ string;
export type ExtendedAreaLevelsUpdateApiResponse =
  /** status 200 An updated extended area level */ {
    data: ExtendedAreaLevel;
  };
export type ExtendedAreaLevelsUpdateApiArg = {
  /** The extended area level id */
  id: string;
  /** Extended Area Level update payload */
  body: {
    data: UpdateExtendedAreaLevel;
  };
};
export type PutV1ExtendedAreaLevelsByIdApiResponse =
  /** status 200 An updated extended area level */ {
    data: ExtendedAreaLevel;
  };
export type PutV1ExtendedAreaLevelsByIdApiArg = {
  /** The extended area level id */
  id: string;
  /** Extended Area Level update payload */
  body: {
    data: UpdateExtendedAreaLevel;
  };
};
export type RateCardsIndexApiResponse =
  /** status 200 A collection of rate cards */ {
    data: RateCard[];
    meta: PaginationMetadata;
  };
export type RateCardsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `rate_type`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    fulfillment_center_id?: string;
    rate_type?: "buy" | "sell";
    service_level_id?: string;
    service_method_id?: string;
    shipper_id?: string;
  };
};
export type ShippersShowApiResponse = /** status 200 The shipper */ {
  data: Shipper;
};
export type ShippersShowApiArg =
  /** The shipper id or external reference */ string;
export type ShippersUpdateApiResponse = /** status 200 An updated shipper */ {
  data: Shipper;
};
export type ShippersUpdateApiArg = {
  /** The shipper id or external reference */
  id: string;
  /** Shipper update payload */
  body: {
    data: UpdateShipper;
  };
};
export type PutV1ShippersByIdApiResponse =
  /** status 200 An updated shipper */ {
    data: Shipper;
  };
export type PutV1ShippersByIdApiArg = {
  /** The shipper id or external reference */
  id: string;
  /** Shipper update payload */
  body: {
    data: UpdateShipper;
  };
};
export type AccessorialsAccessorialsConfigsDeleteApiResponse =
  /** status 204 Successful deletion of an accessorial config */ string;
export type AccessorialsAccessorialsConfigsDeleteApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The accessorial config id */
  id: string;
};
export type AccessorialsAccessorialsConfigsShowApiResponse =
  /** status 200 The accessorial config */ {
    data: AccessorialConfig;
  };
export type AccessorialsAccessorialsConfigsShowApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The accessorial config id */
  id: string;
};
export type AccessorialsAccessorialsConfigsUpdateApiResponse =
  /** status 200 An updated accessorial config */ {
    data: AccessorialConfig;
  };
export type AccessorialsAccessorialsConfigsUpdateApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The accessorial config id */
  id: string;
  /** Accessorial config update payload */
  body: {
    data: UpdateAccessorialConfig;
  };
};
export type PutV1AccessorialsByAccessorialIdConfigsAndIdApiResponse =
  /** status 200 An updated accessorial config */ {
    data: AccessorialConfig;
  };
export type PutV1AccessorialsByAccessorialIdConfigsAndIdApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The accessorial config id */
  id: string;
  /** Accessorial config update payload */
  body: {
    data: UpdateAccessorialConfig;
  };
};
export type OversizedSurchargesIndexApiResponse =
  /** status 200 A collection of oversized surcharges */ {
    data: OversizedSurcharge[];
    meta: PaginationMetadata;
  };
export type OversizedSurchargesIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `oversized_type`, `zone_code`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    accessorial_id?: string;
    carrier_id?: string;
    oversized_type?: string;
    service_level_id?: string;
    shipper_id?: string;
    zone_code?: string;
  };
};
export type OversizedSurchargesCreateApiResponse =
  /** status 201 The created oversized surcharge */ {
    data: OversizedSurcharge;
  };
export type OversizedSurchargesCreateApiArg =
  /** Oversized surcharge creation payload */ {
    data: CreateOversizedSurcharge;
  };
export type ServiceLevelsDeleteApiResponse =
  /** status 204 Successful deletion of a service level */ string;
export type ServiceLevelsDeleteApiArg = /** The service level id */ string;
export type ServiceLevelsShowApiResponse = /** status 200 The service level */ {
  data: ServiceLevel;
};
export type ServiceLevelsShowApiArg = /** The service level id */ string;
export type ServiceLevelsUpdateApiResponse =
  /** status 200 An updated service level */ {
    data: ServiceLevel;
  };
export type ServiceLevelsUpdateApiArg = {
  /** The service level id */
  id: string;
  /** Service level update payload */
  body: {
    data: UpdateServiceLevel;
  };
};
export type PutV1ServiceLevelsByIdApiResponse =
  /** status 200 An updated service level */ {
    data: ServiceLevel;
  };
export type PutV1ServiceLevelsByIdApiArg = {
  /** The service level id */
  id: string;
  /** Service level update payload */
  body: {
    data: UpdateServiceLevel;
  };
};
export type ExtendedAreaLevelsIndexApiResponse =
  /** status 200 A collection of extended area levels */ {
    data: ExtendedAreaLevel[];
    meta: PaginationMetadata;
  };
export type ExtendedAreaLevelsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type ExtendedAreaLevelsCreateApiResponse =
  /** status 201 The created extended area level */ {
    data: ExtendedAreaLevel;
  };
export type ExtendedAreaLevelsCreateApiArg =
  /** Extended Area Level creation payload */ {
    data: CreateExtendedAreaLevel;
  };
export type ServiceLevelsIndexApiResponse =
  /** status 200 A collection of service levels */ {
    data: ServiceLevel[];
    meta: PaginationMetadata;
  };
export type ServiceLevelsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type ServiceLevelsCreateApiResponse =
  /** status 201 The created service level */ {
    data: ServiceLevel;
  };
export type ServiceLevelsCreateApiArg = /** Service level creation payload */ {
  data: CreateServiceLevel;
};
export type FuelGroupsIndexApiResponse =
  /** status 200 A collection of fuel groups */ {
    data: FuelGroup[];
    meta: PaginationMetadata;
  };
export type FuelGroupsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `rate_type`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type FuelGroupsCreateApiResponse =
  /** status 201 The created fuel group */ {
    data: FuelGroup;
  };
export type FuelGroupsCreateApiArg = /** Fuel Group creation payload */ {
  data: CreateFuelGroup;
};
export type DeliveryEstimateGetDeliveryEstimateApiResponse =
  /** status 200 The delivery estimate */ {
    data: DeliveryEstimate;
  };
export type DeliveryEstimateGetDeliveryEstimateApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Delivery estimate request payload */
  body: {
    data: RequestDeliveryEstimate;
  };
};
export type NotFound = {
  errors: {
    detail?: string;
  };
};
export type Zone = {
  destination_country: string;
  destination_zip_end: string;
  destination_zip_start: string;
  id: string;
  inserted_at: string;
  updated_at: string;
  zone_code: string;
  zone_group_id: string;
};
export type JsonErrorResponse = {
  errors: {
    detail: string;
    source: {
      pointer: string;
    };
    title: string;
  }[];
};
export type UpdateZone = {
  destination_country?: string;
  destination_zip_end?: string;
  destination_zip_start?: string;
  zone_code?: string;
};
export type ExtendedAreaSurcharge = {
  accessorial_id: string;
  effective_at: string;
  expires_at: string;
  extended_area_level_id: string | null;
  id: string;
  inserted_at: string;
  updated_at: string;
  zone_code: string | null;
};
export type UpdateExtendedAreaSurcharge = {
  accessorial_id?: string;
  effective_at?: string;
  expires_at?: string;
  extended_area_level_id?: string | null;
  zone_code?: string | null;
};
export type ShowCarrierAccount = {
  account_id?: string;
  address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  carrier_name?: string;
  description?: string | null;
  id?: string;
  inserted_at?: string;
  label_provider?: ("shipium" | "easy_post") | null;
  updated_at?: string;
};
export type PaginationMetadata = {
  page_number: number;
  page_size: number;
  total_entries: number;
  total_pages: number;
};
export type CreateCarrierAccount = {
  account_id: string;
  address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  carrier_name: string;
  description?: string | null;
  label_provider: ("shipium" | "easy_post") | null;
};
export type ServiceLevel = {
  display_name: string;
  id: string;
  inserted_at: string;
  name: string;
  updated_at: string;
};
export type ShipperAccessorial = {
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  accessorial_id: string;
  carrier: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id: string | null;
  effective_at: string;
  expires_at: string;
  fulfillment_center: {
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    alias: string;
    id: string;
    inserted_at: string;
    name: string;
    shipium_origin_id: string;
    updated_at: string;
  } | null;
  fulfillment_center_id: string | null;
  id: string;
  inserted_at: string;
  rate: number;
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id: string | null;
  service_method: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id: string | null;
  shipper: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id: string;
  updated_at: string;
};
export type ShipperCarrierFulfillment = {
  carrier_account_id: string;
  fulfillment_center_id: string;
  id?: string;
  inserted_at?: string;
  shipper_id: string;
  updated_at?: string;
};
export type Carrier = {
  aliases: string[] | null;
  display_name: string;
  id: string;
  inserted_at: string;
  name: string;
  tracking_link_template: string | null;
  tracking_number_lengths: number[] | null;
  tracking_number_prefixes: string[] | null;
  updated_at: string;
};
export type CreateCarrier = {
  aliases?: string[] | null;
  display_name: string;
  name: string;
  tracking_link_template?: string | null;
  tracking_number_lengths?: number[] | null;
  tracking_number_prefixes?: string[] | null;
};
export type VendorServiceMethodMapping = {
  id: string;
  inserted_at: string;
  service_method_id: string;
  updated_at: string;
  vendor_id: string;
  vendor_service_method: string;
};
export type UpdateVendorServiceMethodMapping = {
  service_method_id?: string;
  vendor_service_method?: string;
};
export type Shipper = {
  default_service_method: string | null;
  enabled_label_providers: ("shipium" | "easy_post")[];
  external_reference: string | null;
  fallback_service_method: string | null;
  id: string;
  inserted_at?: string;
  name: string;
  updated_at?: string;
};
export type CreateShipper = {
  default_service_method?: string | null;
  enabled_label_providers?: ("shipium" | "easy_post")[];
  external_reference: string | null;
  fallback_service_method?: string | null;
  name: string;
};
export type ServiceMethod = {
  carrier_id: string;
  display_name: string;
  id: string;
  inserted_at: string;
  name: string;
  service_levels: ServiceLevel[] | null;
  updated_at: string;
};
export type Accessorial = {
  calculate_fuel: boolean;
  default_rate: number;
  description: string;
  id: string;
  inserted_at: string;
  name: string;
  updated_at: string;
};
export type UpdateAccessorial = {
  calculate_fuel?: boolean;
  default_rate?: number;
  description?: string;
  name?: string;
};
export type BillingAccount = {
  account_number: string;
  billing_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  carrier_name: "fedex" | "usps" | "ups" | "dhl";
  id: string;
  inserted_at?: string;
  name: string;
  service_method?: string | null;
  shipper_id: string;
  updated_at?: string;
};
export type CreateBillingAccount = {
  account_number: string;
  billing_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  carrier_name: "fedex" | "usps" | "ups" | "dhl";
  name: string;
  service_method?: string | null;
};
export type FulfillmentCenter = {
  address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  alias: string;
  id: string;
  inserted_at: string;
  name: string;
  shipium_origin_id: string;
  updated_at: string;
};
export type CreateFulfillmentCenter = {
  address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  alias: string;
  name: string;
  shipium_origin_id: string;
};
export type VendorCarrierMapping = {
  carrier_id: string;
  id: string;
  inserted_at: string;
  updated_at: string;
  vendor_carrier: string;
  vendor_id: string;
};
export type CreateVendorCarrierMapping = {
  carrier_id: string;
  vendor_carrier: string;
};
export type RateCard = {
  effective_at: string;
  expires_at: string;
  fulfillment_center: {
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    alias: string;
    id: string;
    inserted_at: string;
    name: string;
    shipium_origin_id: string;
    updated_at: string;
  } | null;
  fulfillment_center_id: string | null;
  id: string;
  inserted_at: string;
  rate_type: "buy" | "sell";
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id: string;
  service_method: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id: string | null;
  shipper: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id: string;
  updated_at: string;
};
export type CreateRateCard = {
  effective_at: string;
  expires_at: string;
  fulfillment_center_id?: string | null;
  rate_type: "buy" | "sell";
  service_level_id: string;
  service_method_id?: string | null;
};
export type ShowRegistration = {
  carrier_estimated_delivery_date?: string | null;
  carrier_name?: string;
  delivered_at?: string | null;
  initial_carrier_estimated_delivery_date?: string | null;
  label_provider?: string;
  registered_at?: string | null;
  shipper_external_reference?: string;
  shipper_id?: string;
  source?: string | null;
  tracking_number?: string;
};
export type UpdateRegistration = {
  carrier_estimated_delivery_date?: string | null;
  carrier_name?: string;
  delivered_at?: string | null;
  initial_carrier_estimated_delivery_date?: string | null;
  label_provider?: string;
  registered_at?: string | null;
  shipper_external_reference?: string;
  shipper_id?: string;
  source?: string | null;
  tracking_number?: string;
};
export type UpdateFulfillmentCenter = {
  address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  alias?: string;
  name?: string;
  shipium_origin_id?: string;
};
export type CreateShipperAccessorial = {
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  accessorial_id?: string;
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  effective_at: string;
  expires_at: string;
  fulfillment_center?: {
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    alias: string;
    id: string;
    inserted_at: string;
    name: string;
    shipium_origin_id: string;
    updated_at: string;
  } | null;
  fulfillment_center_id?: string | null;
  rate: number;
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
  service_method?: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id?: string | null;
  shipper?: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id: string;
};
export type UpdateBillingAccount = {
  account_number?: string;
  billing_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  carrier_name?: "fedex" | "usps" | "ups" | "dhl";
  name?: string;
  service_method?: string | null;
};
export type ShowTrackingEvent = {
  carrier_name?: string;
  country_code?: string | null;
  country_subdivision_code?: string | null;
  description?: string | null;
  inserted_at?: string;
  locality?: string | null;
  occurred_at?: string;
  postal_code?: string | null;
  source?: string;
  status?:
    | "registered"
    | "label_printed"
    | "delivered"
    | "exception"
    | "in_transit"
    | "out_for_delivery"
    | "unknown";
  tracking_event_id?: string;
  tracking_number?: string;
  updated_at?: string;
};
export type UpdateTrackingEvent = {
  carrier_name?: string;
  country_code?: string | null;
  country_subdivision_code?: string | null;
  description?: string | null;
  locality?: string | null;
  occurred_at?: string;
  postal_code?: string | null;
  source?: string;
  status?:
    | "registered"
    | "label_printed"
    | "delivered"
    | "exception"
    | "in_transit"
    | "out_for_delivery"
    | "unknown";
  tracking_number?: string;
};
export type Vendor = {
  display_name: string;
  id: string;
  inserted_at: string;
  name: string;
  updated_at: string;
};
export type UpdateVendor = {
  display_name?: string;
  name?: string;
};
export type Rating = {
  buy_rate?: number | null;
  buy_rate_breakdown?: {
    accessorial_breakdown?: object[];
    accessorial_surcharge?: number;
    base_rate?: number;
  } | null;
  external_reference: string;
  id: string;
  inserted_at?: string;
  is_rerate: boolean;
  parcel_id?: string;
  rerate_parameters?: object | null;
  sell_rate?: number | null;
  sell_rate_breakdown?: {
    accessorial_breakdown?: object[];
    accessorial_surcharge?: number;
    base_rate?: number;
  } | null;
  service_level_id?: string;
  tms_buy_rate?: number;
  tms_rates?: object;
  tms_reference?: string;
  updated_at?: string;
  zone_code?: string | null;
};
export type Item = {
  description: string;
  hazmat?: boolean | null;
  hazmat_info?: {
    battery_count?: number | null;
    battery_installation?: ("installed" | "uninstalled") | null;
    contains_lithium?: boolean;
    hazard_class?: string;
    limited_quantity?: boolean;
    lithium_ion_content?: number | null;
    packing_group?: ("i" | "ii" | "iii") | null;
    proper_shipping_name?: string;
    transport_mode?:
      | "any"
      | "cargo_aircraft_only"
      | "ground"
      | "passenger_and_cargo_aircraft";
    un_id?: string;
  } | null;
  origin_country: string;
  price: number;
  product_sku: string;
  quantity: number;
  tariff_code?: string | null;
  unit_price?: number;
  weight: number;
};
export type Document = {
  document_encoding?: string;
  document_format: string;
  document_type?:
    | "commercial_invoice"
    | "electronic_export_information"
    | "cn22"
    | "air_waybill"
    | "multiple"
    | "nafta_certificate_of_origin"
    | "high_value_report"
    | "cod_return_label"
    | "order_summary";
  encoded_document: string;
  expires_at?: string;
  id: string;
  inserted_at?: string;
  is_electronic?: boolean;
  parcel_id: string;
  updated_at?: string;
};
export type ShowParcel = {
  id?: string;
  external_source_id?: string | null;
  tms_zone_code?: string | null;
  fulfillment_center_id?: string | null;
  return_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  ratings?: Rating[];
  items?: Item[];
  carrier_selection_method?: string | null;
  saturday_delivery?: boolean | null;
  weight?: number;
  incoterm?: ("DAP" | "DDP" | "DDU") | null;
  business_days_in_transit?: number | null;
  billing_account_id?: string | null;
  voided_at?: string | null;
  desired_delivery_date?: string | null;
  inserted_at?: string;
  updated_at?: string;
  tracking_url?: string;
  label_provider?: ("easy_post" | "shipium") | null;
  service_method?: string | null;
  external_label_id?: string | null;
  tracking_number?: string;
  to_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  label_rate?: number;
  carrier_ship_option?: string | null;
  documents?: Document[];
  from_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  length?: number;
  height?: number;
  width?: number;
  carrier_name?: string | null;
  third_party_billing_enabled?: boolean | null;
  shipping_label?: string;
  label_service_method_id?: string | null;
  label_format?: string | null;
  tms_billable_weight?: string | null;
  packaging_material?:
    | (
        | "box"
        | "fedex_one_rate_envelope"
        | "fedex_one_rate_extra_large_box"
        | "fedex_one_rate_large_box"
        | "fedex_one_rate_medium_box"
        | "fedex_one_rate_pak"
        | "fedex_one_rate_small_box"
        | "fedex_one_rate_tube"
      )
    | null;
  currency_code?: string;
  expected_shipped_at_date?: string | null;
  external_id?: string | null;
  signature_required?:
    | (
        | "signature"
        | "resident_signature"
        | "adult_signature"
        | "adult_resident_signature"
      )
    | null;
  is_return?: boolean | null;
};
export type CreateReturnLabel = {
  currency_code: string;
  external_id?: string | null;
  external_source_id: string | null;
  from_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  height?: number;
  items: Item[];
  length?: number;
  order_metadata?: {
    carrier_processing_id?: string | null;
  } | null;
  return_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  tags?: string[] | null;
  to_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  weight?: number;
  width?: number;
};
export type OversizedSurcharge = {
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  accessorial_id: string | null;
  carrier: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id: string | null;
  effective_at: string;
  expires_at: string;
  id: string;
  inserted_at: string;
  max_value: number;
  min_value: number;
  oversized_type: string;
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id: string | null;
  shipper: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id: string | null;
  updated_at: string;
  zone_code: string | null;
};
export type UpdateOversizedSurcharge = {
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  accessorial_id?: string | null;
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  effective_at?: string;
  expires_at?: string;
  max_value?: number;
  min_value?: number;
  oversized_type?: string;
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
  shipper?: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id?: string | null;
  zone_code?: string | null;
};
export type DimensionalFactor = {
  carrier: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id: string | null;
  effective_at: string;
  expires_at: string;
  factor: string | number;
  id: string;
  inserted_at: string;
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id: string | null;
  service_method: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id: string | null;
  shipper: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id: string | null;
  updated_at: string;
};
export type UpdateDimensionalFactor = {
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  effective_at?: string;
  expires_at?: string;
  factor?: string | number;
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
  service_method?: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id?: string | null;
  shipper?: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id?: string | null;
};
export type EvaluatedServiceMethod = {
  carrier_name?: string;
  label_rate?: number;
  service_method_name?: string;
};
export type Rate = {
  carrier_name?: string | null;
  currency_code?: string;
  evaluated_service_methods?: EvaluatedServiceMethod[];
  expected_delivery_at_date?: string | null;
  label_rate?: number;
  metadata?: {
    shipium_shipment_id?: string;
  };
  service_method?: string | null;
};
export type ShowRates = {
  external_source_id?: string | null;
  from_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  height?: number;
  items?: Item[];
  length?: number;
  rates?: Rate[];
  to_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  weight?: number;
  width?: number;
};
export type RateEstimate =
  | {
      business_days_in_transit: number | null;
      currency_code?: string;
      external_source_id?: string | null;
      height?: number;
      incoterm?: ("DAP" | "DDP" | "DDU") | null;
      items?: Item[];
      length?: number;
      locations?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      }[];
      rate_type?: string;
      saturday_delivery?: boolean | null;
      signature_required?:
        | (
            | "signature"
            | "resident_signature"
            | "adult_signature"
            | "adult_resident_signature"
          )
        | null;
      to_address?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      };
      weight?: number;
      width?: number;
    }
  | {
      carrier_ship_option: string | null;
      currency_code?: string;
      external_source_id?: string | null;
      height?: number;
      incoterm?: ("DAP" | "DDP" | "DDU") | null;
      items?: Item[];
      length?: number;
      locations?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      }[];
      rate_type?: string;
      saturday_delivery?: boolean | null;
      signature_required?:
        | (
            | "signature"
            | "resident_signature"
            | "adult_signature"
            | "adult_resident_signature"
          )
        | null;
      to_address?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      };
      weight?: number;
      width?: number;
    }
  | {
      currency_code?: string;
      external_source_id?: string | null;
      height?: number;
      incoterm?: ("DAP" | "DDP" | "DDU") | null;
      items?: Item[];
      length?: number;
      locations?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      }[];
      rate_type?: string;
      saturday_delivery?: boolean | null;
      service_method: string | null;
      signature_required?:
        | (
            | "signature"
            | "resident_signature"
            | "adult_signature"
            | "adult_resident_signature"
          )
        | null;
      to_address?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      };
      weight?: number;
      width?: number;
    }
  | {
      currency_code?: string;
      desired_delivery_date: string | null;
      external_source_id?: string | null;
      height?: number;
      incoterm?: ("DAP" | "DDP" | "DDU") | null;
      items?: Item[];
      length?: number;
      locations?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      }[];
      rate_type?: string;
      saturday_delivery?: boolean | null;
      signature_required?:
        | (
            | "signature"
            | "resident_signature"
            | "adult_signature"
            | "adult_resident_signature"
          )
        | null;
      to_address?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      };
      weight?: number;
      width?: number;
    };
export type CreateParcel = {
  business_days_in_transit?: number | null;
  carrier_ship_option?: string | null;
  currency_code: string;
  desired_delivery_date?: string | null;
  expected_shipped_at_date?: string | null;
  external_id?: string | null;
  external_source_id: string | null;
  fallback_service_method?: string | null;
  from_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  height: number;
  incoterm?: ("DAP" | "DDP" | "DDU") | null;
  items: Item[];
  length: number;
  order_metadata?: {
    carrier_processing_id?: string | null;
  } | null;
  packaging_material?:
    | (
        | "box"
        | "fedex_one_rate_envelope"
        | "fedex_one_rate_extra_large_box"
        | "fedex_one_rate_large_box"
        | "fedex_one_rate_medium_box"
        | "fedex_one_rate_pak"
        | "fedex_one_rate_small_box"
        | "fedex_one_rate_tube"
      )
    | null;
  print_option_1?: string | null;
  print_option_2?: string | null;
  print_option_3?: string | null;
  return_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  saturday_delivery?: boolean | null;
  service_method?: string | null;
  signature_required?:
    | (
        | "signature"
        | "resident_signature"
        | "adult_signature"
        | "adult_resident_signature"
      )
    | null;
  tags?: string[] | null;
  to_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  weight: number;
  width: number;
  zone?: string | null;
};
export type CreateParcelBatch = {
  parcels: CreateParcel[];
};
export type ZipZoneMapping = {
  carrier?: string | null;
  carrier_service_method?: string | null;
  destination_country: string;
  destination_state: string;
  destination_zip_end: string;
  destination_zip_start: string;
  effective_at: string;
  expires_at?: string | null;
  id?: string;
  inserted_at?: string;
  max_weight?: number | null;
  origin_country: string;
  origin_state: string;
  origin_zip_end: string;
  origin_zip_start: string;
  updated_at?: string;
  zone: string;
};
export type CreateZipZoneMapping = {
  carrier?: string | null;
  carrier_service_method?: string | null;
  destination_country: string;
  destination_state: string;
  destination_zip_end: string;
  destination_zip_start: string;
  effective_at: string;
  expires_at?: string | null;
  max_weight?: number | null;
  origin_country: string;
  origin_state: string;
  origin_zip_end: string;
  origin_zip_start: string;
  zone: string;
};
export type CreateAccessorial = {
  calculate_fuel: boolean;
  default_rate: number;
  description: string;
  name: string;
};
export type ExtendedArea = {
  carrier: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id: string | null;
  effective_at: string;
  expires_at: string;
  extended_area_level: {
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  extended_area_level_id: string | null;
  id: string;
  inserted_at: string;
  postal_code: string;
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id: string | null;
  updated_at: string;
};
export type UpdateExtendedArea = {
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  effective_at?: string;
  expires_at?: string;
  extended_area_level?: {
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  extended_area_level_id?: string | null;
  postal_code?: string;
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
};
export type CreateVendorServiceMethodMapping = {
  service_method_id: string;
  vendor_service_method: string;
};
export type UpdateCarrierAccount = {
  account_id?: string;
  carrier_name?: string;
  description?: string | null;
  label_provider?: ("shipium" | "easy_post") | null;
};
export type CarrierAccessorial = {
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  accessorial_id: string | null;
  carrier: Carrier;
  carrier_id: string;
  id: string;
  inserted_at: string;
  name: string;
  updated_at: string;
};
export type UpdateCarrierAccessorial = {
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  accessorial_id?: string | null;
  carrier?: Carrier;
  carrier_id?: string;
  name?: string;
};
export type RerateRequest = {
  external_reference: string;
  from_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  height?: number;
  length?: number;
  service_method?: string;
  to_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  weight?: number;
  width?: number;
};
export type CreateExtendedArea = {
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  effective_at: string;
  expires_at: string;
  extended_area_level?: {
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  extended_area_level_id?: string | null;
  postal_code: string;
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
};
export type UpdateVendorCarrierMapping = {
  carrier_id?: string;
  vendor_carrier?: string;
};
export type CreateCarrierAccessorial = {
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  accessorial_id?: string | null;
  carrier?: Carrier;
  carrier_id: string;
  name: string;
};
export type UpdateRateCard = {
  effective_at?: string;
  expires_at?: string;
  fulfillment_center_id?: string | null;
  rate_type?: "buy" | "sell";
  service_level_id?: string;
  service_method_id?: string | null;
  shipper_id?: string;
};
export type ZoneGroup = {
  carrier: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id: string | null;
  effective_at: string;
  expires_at: string;
  id: string;
  inserted_at: string;
  origin_country: string;
  origin_zip_end: string;
  origin_zip_start: string;
  rate_type: "buy" | "sell";
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id: string;
  service_method: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id: string | null;
  updated_at: string;
};
export type CreateZoneGroup = {
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  effective_at: string;
  expires_at: string;
  origin_country: string;
  origin_zip_end: string;
  origin_zip_start: string;
  rate_type?: "buy" | "sell";
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id: string;
  service_method?: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id?: string | null;
};
export type UpdateServiceMethod = {
  display_name?: string;
  name?: string;
  service_levels?: {
    id?: string;
  }[];
};
export type FuelSurcharge = {
  effective_at: string;
  expires_at: string;
  fuel_group_id: string;
  fuel_percent: string;
  id: string;
  inserted_at: string;
  updated_at: string;
};
export type CreateFuelSurcharge = {
  effective_at: string;
  expires_at: string;
  fuel_group_id?: string;
  fuel_percent: string;
};
export type Registration = {
  carrier_estimated_delivery_date?: string | null;
  carrier_name: string;
  delivered_at?: string | null;
  initial_carrier_estimated_delivery_date?: string | null;
  label_provider: string;
  registered_at?: string | null;
  shipper_external_reference: string;
  shipper_id: string;
  source?: string | null;
  tracking_number: string;
};
export type UpdateFuelSurcharge = {
  effective_at?: string;
  expires_at?: string;
  fuel_group_id?: string;
  fuel_percent?: string;
};
export type UpdateZoneGroup = {
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  effective_at?: string;
  expires_at?: string;
  origin_country?: string;
  origin_zip_end?: string;
  origin_zip_start?: string;
  rate_type?: "buy" | "sell";
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string;
  service_method?: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id?: string | null;
};
export type RateRecord = {
  flat_rate: number | null;
  id: string;
  inserted_at: string;
  max_weight: number;
  min_weight: number;
  rate_card_id?: string;
  rating_type: "flat" | "variable" | "hybrid";
  updated_at: string;
  variable_rate: number | null;
  zone_code?: string;
};
export type CreateRateRecord = {
  flat_rate?: number | null;
  max_weight: number;
  min_weight: number;
  rating_type: "flat" | "variable" | "hybrid";
  variable_rate?: number | null;
  zone_code: string;
};
export type UpdateShipperAccessorial = {
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  accessorial_id?: string;
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  effective_at?: string;
  expires_at?: string;
  fulfillment_center?: {
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    alias: string;
    id: string;
    inserted_at: string;
    name: string;
    shipium_origin_id: string;
    updated_at: string;
  } | null;
  fulfillment_center_id?: string | null;
  rate?: number;
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
  service_method?: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id?: string | null;
  shipper?: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id?: string;
};
export type AccessorialConfig = {
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  };
  accessorial_id: string;
  carrier: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id: string | null;
  fulfillment_center: {
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    alias: string;
    id: string;
    inserted_at: string;
    name: string;
    shipium_origin_id: string;
    updated_at: string;
  } | null;
  fulfillment_center_id: string | null;
  id: string;
  inserted_at: string;
  run_type: "use_buy_rate" | "use_shipper_rate" | "ignore";
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id: string | null;
  service_method: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id: string | null;
  shipper: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id: string | null;
  updated_at: string;
};
export type CreateServiceMethod = {
  display_name: string;
  name: string;
  service_levels?: {
    id?: string;
  }[];
};
export type BatchJob = {
  error?: string | null;
  id: string;
  parcel?: {
    print_option_1?: string | null;
    id?: string;
    external_source_id?: string | null;
    tms_zone_code?: string | null;
    external_order_number?: string | null;
    fulfillment_center_id?: string | null;
    return_address?: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    ratings?: Rating[];
    items?: Item[];
    carrier_selection_method?: string | null;
    saturday_delivery?: boolean | null;
    weight?: number;
    print_option_2?: string | null;
    incoterm?: ("DAP" | "DDP" | "DDU") | null;
    print_option_3?: string | null;
    business_days_in_transit?: number | null;
    billing_account_id?: string | null;
    voided_at?: string | null;
    desired_delivery_date?: string | null;
    inserted_at?: string;
    updated_at?: string;
    order_metadata?: {
      carrier_processing_id?: string | null;
    } | null;
    expected_delivery_at_date?: string | null;
    zone?: string | null;
    tracking_url?: string;
    label_provider?: ("easy_post" | "shipium") | null;
    service_method?: string | null;
    tags?: string[] | null;
    external_label_id?: string | null;
    tracking_number?: string;
    to_address?: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    };
    label_rate?: number;
    carrier_ship_option?: string | null;
    documents?: Document[];
    fallback_service_method?: string | null;
    from_address?: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    };
    length?: number;
    height?: number;
    shipper_id?: string;
    width?: number;
    carrier_name?: string | null;
    third_party_billing_enabled?: boolean | null;
    shipping_label?: string;
    label_service_method_id?: string | null;
    label_format?: string | null;
    tms_billable_weight?: string | null;
    packaging_material?:
      | (
          | "box"
          | "fedex_one_rate_envelope"
          | "fedex_one_rate_extra_large_box"
          | "fedex_one_rate_large_box"
          | "fedex_one_rate_medium_box"
          | "fedex_one_rate_pak"
          | "fedex_one_rate_small_box"
          | "fedex_one_rate_tube"
        )
      | null;
    currency_code?: string;
    expected_shipped_at_date?: string | null;
    external_id?: string | null;
    signature_required?:
      | (
          | "signature"
          | "resident_signature"
          | "adult_signature"
          | "adult_resident_signature"
        )
      | null;
    is_return?: boolean | null;
  } | null;
  status: "completed" | "pending" | "failed";
};
export type TrackingLocation = {
  city?: string | null;
  country?: string | null;
  object: "TrackingLocation";
  state?: string | null;
  zip?: string | null;
} | null;
export type CarrierDetail = {
  alternate_identifier?: string | null;
  container_type?: string | null;
  destination_location?: string | null;
  destination_tracking_location?: TrackingLocation;
  est_delivery_date_local?: string | null;
  est_delivery_time_local?: string | null;
  guaranteed_delivery_date?: string | null;
  initial_delivery_attempt?: string | null;
  object?: "CarrierDetail";
  origin_location?: string | null;
  origin_tracking_location?: TrackingLocation;
  service?: string | null;
} | null;
export type Fee = {
  amount: number;
  charged: boolean;
  object: "Fee";
  refunded: boolean;
  type:
    | "LabelFee"
    | "CarbonOffsetFee"
    | "PostageFee"
    | "InsuranceFee"
    | "TrackerFee";
};
export type TrackingDetail = {
  carrier_code?: string | null;
  datetime: string;
  description?: string | null;
  message: string;
  object: "TrackingDetail";
  source: string;
  status: string;
  status_detail: string | null;
  tracking_location?: TrackingLocation;
};
export type Tracker = {
  carrier: string;
  carrier_detail?: CarrierDetail;
  created_at: string;
  est_delivery_date?: string | null;
  fees?: Fee[];
  finalized: boolean;
  id: string;
  is_return: boolean;
  mode: "test" | "production";
  object: "Tracker";
  public_url: string;
  shipment_id?: string | null;
  signed_by?: string | null;
  status: string;
  status_detail: string;
  tracking_code: string;
  tracking_details?: TrackingDetail[];
  updated_at: string;
  weight?: number | null;
};
export type WebhookEvent = {
  created_at: string;
  description: string | null;
  id: string;
  mode: string;
  object: string;
  result: Tracker;
  updated_at: string;
};
export type UpdateRateRecord = {
  flat_rate?: number | null;
  id?: string;
  inserted_at?: string;
  max_weight?: number;
  min_weight?: number;
  rate_card_id?: string;
  rating_type?: "flat" | "variable" | "hybrid";
  updated_at?: string;
  variable_rate?: number | null;
  zone_code?: string;
};
export type CreateZone = {
  destination_country: string;
  destination_zip_end: string;
  destination_zip_start: string;
  zone_code: string;
};
export type CreateAccessorialConfig = {
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  };
  accessorial_id: string;
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  fulfillment_center?: {
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    alias: string;
    id: string;
    inserted_at: string;
    name: string;
    shipium_origin_id: string;
    updated_at: string;
  } | null;
  fulfillment_center_id?: string | null;
  run_type: "use_buy_rate" | "use_shipper_rate" | "ignore";
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
  service_method?: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id?: string | null;
  shipper?: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id?: string | null;
};
export type FuelGroup = {
  carrier: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id: string | null;
  id: string;
  inserted_at: string;
  name: string;
  rate_type: "buy" | "sell";
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id: string | null;
  service_method: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id: string | null;
  shipper: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id: string | null;
  updated_at: string;
};
export type UpdateFuelGroup = {
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  name?: string;
  rate_type?: "buy" | "sell";
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
  service_method?: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id?: string | null;
  shipper?: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id?: string | null;
};
export type CreateVendor = {
  display_name: string;
  name: string;
};
export type CreateExtendedAreaSurcharge = {
  accessorial_id: string;
  effective_at: string;
  expires_at: string;
  extended_area_level_id?: string | null;
  zone_code?: string | null;
};
export type DetailedTrackingEvent = {
  carrierDescription?: string;
  city?: string | null;
  country?: string | null;
  eventDate: string;
  postalCode?: (string | null) | number;
  region?: string | null;
  shipmentStatus:
    | "Delivered"
    | "Exception"
    | "In Transit"
    | "Label Printed"
    | "Out For Delivery"
    | "Registered"
    | "Unknown";
};
export type Tracking = {
  carrierEstimatedDeliveryDate?: string | null;
  carrierId?: string;
  carrierServiceMethodId?: string | null;
  carrierTrackingId?: string;
  deliveredAtDateTime?: string | null;
  originalCarrierEstimatedDeliveryDate?: string | null;
  shipiumTrackingId: string;
  shipmentStatus?:
    | "Delivered"
    | "Exception"
    | "In Transit"
    | "Label Printed"
    | "Out For Delivery"
    | "Registered"
    | "Unknown";
  shippedDateTime?: string | null;
  trackingEvents: DetailedTrackingEvent[];
};
export type ShipiumEvent = {
  metadata?: {
    eventId?: string;
    eventTimestamp?: string;
    eventType?: "tracking_updated";
    payloadSchemaVersion?: "v1";
    testEvent?: boolean;
  };
  payload: {
    trackings: Tracking[];
  };
};
export type ShipiumWebhookEvent = {
  events: ShipiumEvent[];
};
export type TrackingEvent = {
  carrier_name?: string;
  country_code?: string | null;
  country_subdivision_code?: string | null;
  description?: string | null;
  inserted_at?: string;
  locality?: string | null;
  occurred_at: string;
  postal_code?: string | null;
  source: string;
  status:
    | "registered"
    | "label_printed"
    | "delivered"
    | "exception"
    | "in_transit"
    | "out_for_delivery"
    | "unknown";
  tracking_event_id?: string;
  tracking_number: string;
  updated_at?: string;
};
export type CreateDimensionalFactor = {
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  effective_at: string;
  expires_at: string;
  factor: string | number;
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
  service_method?: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id?: string | null;
  shipper?: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id?: string | null;
};
export type UpdateCarrier = {
  aliases?: string[] | null;
  display_name?: string;
  name?: string;
  tracking_link_template?: string | null;
  tracking_number_lengths?: number[] | null;
  tracking_number_prefixes?: string[] | null;
};
export type ExtendedAreaLevel = {
  id: string;
  inserted_at: string;
  name: string;
  updated_at: string;
};
export type UpdateExtendedAreaLevel = {
  name?: string;
};
export type UpdateShipper = {
  default_service_method?: string | null;
  enabled_label_providers?: ("shipium" | "easy_post")[];
  fallback_service_method?: string | null;
  name?: string;
};
export type UpdateAccessorialConfig = {
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  };
  accessorial_id?: string;
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  fulfillment_center?: {
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    alias: string;
    id: string;
    inserted_at: string;
    name: string;
    shipium_origin_id: string;
    updated_at: string;
  } | null;
  fulfillment_center_id?: string | null;
  run_type?: "use_buy_rate" | "use_shipper_rate" | "ignore";
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
  service_method?: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id?: string | null;
  shipper?: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id?: string | null;
};
export type CreateOversizedSurcharge = {
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  accessorial_id: string | null;
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  effective_at: string;
  expires_at: string;
  max_value: number;
  min_value: number;
  oversized_type: string;
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
  shipper?: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id?: string | null;
  zone_code?: string | null;
};
export type UpdateServiceLevel = {
  display_name?: string;
  name?: string;
};
export type CreateExtendedAreaLevel = {
  name: string;
};
export type CreateServiceLevel = {
  display_name: string;
  name: string;
};
export type CreateFuelGroup = {
  carrier?: {
    aliases: string[] | null;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    tracking_link_template: string | null;
    tracking_number_lengths: number[] | null;
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  name: string;
  rate_type: "buy" | "sell";
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
  service_method?: {
    carrier_id: string;
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    service_levels: ServiceLevel[] | null;
    updated_at: string;
  } | null;
  service_method_id?: string | null;
  shipper?: {
    default_service_method: string | null;
    enabled_label_providers: ("shipium" | "easy_post")[];
    external_reference: string | null;
    fallback_service_method: string | null;
    id: string;
    inserted_at?: string;
    name: string;
    updated_at?: string;
  } | null;
  shipper_id?: string | null;
};
export type DeliveryEstimate = {
  alias: string;
  destination_country_code: string;
  destination_postal_code: string;
  estimated_days_in_transit: number;
  estimated_delivery_date: string;
  expected_ship_date: string;
  inserted_at?: string;
  request_date_override?: string;
  ship_option?: "standard";
  updated_at?: string;
};
export type RequestDeliveryEstimate = {
  alias: string;
  destination_country_code: string;
  destination_postal_code: string;
  expected_ship_date: string;
  request_date_override?: string;
  ship_option?: "standard";
};
